import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"

import { Link, useLocation } from "react-router-dom"
import classnames from "classnames"
import FeaturesTable from "../../marketResearch/appTracking/FeaturesTable"
import RankedDashedLine from "../charts-graphs/RankDashedLine"
import ScreenShotCarousel from "./ScreenShotCarousel"
import Reviews from "./Reviews"
import { api } from "../../../utils/variables"
import axios from "axios"
import { FaInfoCircle } from "react-icons/fa"
import TrackingDetailsPopup from "../Modals/TrackingDetails"
import Apaexlinecolumn from "../charts-graphs/ApexLineChart"
import KTRLineChart from "../charts-graphs/KTRLineChart"

export default function InfoTabs({
  appid,
  appListData,
  trackedApp,
  selectedTab,
  onAppSelect,
}) {
  const location = useLocation()
  // const { params } = useParams()
  const { app_id, data, selected_tab } = location.state || {}
  const [activeTab1, setactiveTab1] = useState("1")
  // const [showTrackData, setshowTrackData] = useState(true)
  const storedComparisonApps = JSON.parse(
    sessionStorage.getItem("comparisonBetween")
  )

  const itemIds = trackedApp.map(app => app.game_details?.item_id)

  const [selectedMetric, setSelectedMetric] = useState(null) // Track the selected metric
  const [metricValue, setMetricValue] = useState(null)
  const [btnLoader, setBtnLoader] = useState(false)

  const [modal_toggle, setmodal_toggle] = useState(false)

  const ktrCardRef = useRef(null) // Reference for the KTR card
  const kuRef = useRef(null) //reference for KU card
  const asrCardRef = useRef(null) //reference for asr card
  const downRef = useRef(null) //reference for download card
  const aurRef = useRef(null) //reference for aur card
  const urvRef = useRef(null) //reference for urv card

  const scrollToKTR = () => {
    console.log(ktrCardRef.current)
    if (ktrCardRef.current) {
      ktrCardRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  const scrollToKU = () => {
    console.log(kuRef.current)
    if (kuRef.current) {
      kuRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const scrollToASR = () => {
    console.log(asrCardRef.current)
    if (asrCardRef.current) {
      asrCardRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const scrollToDownloads = () => {
    console.log(downRef.current)
    if (downRef.current) {
      downRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  const scrollToAur = () => {
    console.log(aurRef.current)
    if (aurRef.current) {
      aurRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  const scrollToUrv = () => {
    console.log(urvRef.current)
    if (urvRef.current) {
      urvRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const toggle_modal = (metric = null, value) => {
    setSelectedMetric(metric) // Set the selected metric
    setmodal_toggle(prev => !prev) // Toggle modal visibility
    setMetricValue(value)
  }

  useEffect(() => {
    console.log(appid)
    // console.log(storedComparisonApps)
    // console.log(selectedTab)

    fetchAppHistory(appid)
    //check if the page is redirected from tracking page
    if (selectedTab == "track") {
      setactiveTab1("4")
    }

    // console.log(`List on load: ${comparisonBetween}`)
    // setComparisonBetween(comparisonList)
  }, [])

  useEffect(() => {
    setBtnLoader(false)
  }, [trackedApp])

  const formatDateToYYYYMMDD = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // Add 1 to month (0-indexed) and pad with leading zero
    const day = String(date.getDate()).padStart(2, "0") // Pad day with leading zero if necessary
    return `${year}-${month}-${day}`
  }

  const onemonthbackDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1 - 1).padStart(2, "0") // Add 1 to month (0-indexed) and pad with leading zero
    const day = String(date.getDate()).padStart(2, "0") // Pad day with leading zero if necessary
    // if (month == 0) return `${year}-12-${day}`
    // else return `${year}-${month}-${day}`
    return `${year}-${month}-${day}`
  }

  const addApp = async value => {
    //const gameid ={ item_id: value }
    const gameid = { item_id: value }
    console.log(gameid)
    try {
      const res = await axios.post(`${api}/tracker/tracking/app/`, gameid, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      console.log(res)
    } catch (error) {
      console.error("Error sending data:", error)
    }
  }

  // async function removeApp(val) {
  //   // setLoading(true)
  //   const gameid = { item_id: val }
  //   try {
  //     const res = await axios.delete(`${api}/tracker/tracking/app.remove`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //       },
  //       data: { item_id: val }, // Adjust this key if the payload requires a different value
  //     })
  //     console.log(res)
  //     await callAppTrackapi()
  //   } catch (error) {
  //     console.error("Error sending data:", error)
  //   } finally {
  //     setBtnLoader(false)
  //   }
  // }
  // const callAppTrackapi = async () => {
  //   await axios
  //     .get(`${api}/tracker/tracking/app`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //       },
  //     })
  //     .then(res => {
  //       console.log(res.data.games)
  //       //adding data in session item
  //       sessionStorage.setItem(
  //         "comparisonBetween",
  //         JSON.stringify(res.data.games)
  //       )
  //     })
  // }

  const [startDate, setStartDate] = useState(onemonthbackDate(new Date()))
  const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(new Date()))

  const [ktrStartDate, setKtrStartDate] = useState(onemonthbackDate(new Date()))
  const [ktrEndDate, setKtrEndDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [asrStartDate, setAsrStartDate] = useState(onemonthbackDate(new Date()))
  const [asrEndDate, setAsrEndDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [kuStartDate, setKuStartDate] = useState(onemonthbackDate(new Date()))
  const [kuEndDate, setKuEndDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [dStartDate, setDStartDate] = useState(onemonthbackDate(new Date()))
  const [dEndDate, setDEndDate] = useState(formatDateToYYYYMMDD(new Date()))

  const [aurStartDate, setAurStartDate] = useState(onemonthbackDate(new Date()))
  const [aurEndDate, setAurEndDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [urvStartDate, setUrvStartDate] = useState(onemonthbackDate(new Date()))
  const [urvEndDate, setUrvEndDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [formstartDate, setformStartDate] = useState()

  const [appHistoryData, setAppHistoryData] = useState([])

  const fetchAppHistory = async val => {
    try {
      // Use Promise.all to fetch data for each app based on item_id
      axios
        .post(
          `${api}/tracker/tracking/app.history`,
          { item_id: val },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then(res => {
          console.log(res.data.history.historic_entries)
          setAppHistoryData(res.data.history.historic_entries)
        })
    } catch (error) {
      console.error("Error fetching app history data:", error)
    }
  }

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  function convertEpochToDate(epochTime) {
    const date = new Date(epochTime * 1000) // Multiply by 1000 to convert from seconds to milliseconds

    const day = String(date.getDate()).padStart(2, "0") // Get day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0") // Get month (0-based, so add 1)
    const year = date.getFullYear().toString().slice(-4) // Get last 2 digits of the year
    const hours = String(date.getHours()).padStart(2, "0") // Get hours
    const minutes = String(date.getMinutes()).padStart(2, "0") // Get minutes

    // Format the date as dd-mm-yy hh:mm
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`

    return formattedDate
  }
  return (
    <>
      <Nav pills className="nav-justified bg-light">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "1",
            })}
            onClick={() => {
              toggle1("1")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">About</span>
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "2",
            })}
            onClick={() => {
              toggle1("2")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Category Ranking</span>
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "3",
            })}
            onClick={() => {
              toggle1("3")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Ratings and Reviews</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "4",
            })}
            onClick={() => {
              toggle1("4")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Tracking</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab1} className="p-3 text-muted">
        <TabPane tabId="1">
          <div className="d-flex flex-wrap justify-content-between gap-1 mt-3">
            <Card
              outline
              color="white"
              className="border border-white"
              style={{ width: "550px" }}
            >
              <CardHeader className="bg-transparent border-white text-white">
                Release Detail
              </CardHeader>
              <CardBody className="p-4">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <tbody>
                      <tr>
                        <td>Current Version :</td>
                        <td className="text-end">
                          {appListData?.version || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Age Rating : </td>
                        <td className="text-end">
                          {appListData?.age_rating || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Publisher:</td>
                        <td className="text-end">
                          {appListData?.publisher || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Release Date : </td>
                        <td className="text-end">
                          {appListData?.created_at || "-"}
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <th>File Size :</th>
                        <td className="text-end">
                          <span className="fw-bold">
                            {appListData?.size / 1000000 -
                              (appListData?.size % 1000000) / 1000000 ||
                              "0"}{" "}
                            MB
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>

            <Card
              outline
              color="white"
              className="border border-white"
              style={{ width: "550px" }}
            >
              <CardHeader className="bg-transparent border-white text-white">
                Description
              </CardHeader>
              <CardBody>
                <CardText>{appListData?.description || "NA"}</CardText>
              </CardBody>
            </Card>
          </div>
          <Card style={{ width: "100%" }}>
            <CardHeader>Screenshots</CardHeader>
            <CardBody>
              <ScreenShotCarousel appData={appListData}></ScreenShotCarousel>
            </CardBody>
          </Card>
        </TabPane>
        {/* <TabPane tabId="2">
          <div className="mt-3">
            <p>
              <b>Rank Development</b>
            </p>
            <div className="mt-5 d-flex flex-wrap justify-content-between">
              <div className="d-flex flex-wrap gap-4">
                <label htmlFor="example-date-input" className="col-form-label">
                  From
                </label>
                <div className="">
                  <input
                    className="form-control"
                    type="date"
                    defaultValue={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    id="example-date-input"
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap gap-4">
                <label htmlFor="example-date-input" className="col-form-label">
                  To
                </label>
                <div className="">
                  <input
                    className="form-control"
                    type="date"
                    defaultValue={endDate}
                    onChange={e => setEndDate(e.target.value)}
                    id="example-date-input"
                  />
                </div>
              </div>
            </div>
            <hr></hr>
            <div>
              <RankedDashedLine
                jsonData={appHistoryData}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </TabPane> */}
        <TabPane tabId="3">
          <Reviews appdata={appListData}></Reviews>
        </TabPane>
        <TabPane tabId="4">
          {/* Check if game_id is in itemIds */}
          <div className="mt-3">
            {itemIds.includes(appListData.item_id) ? (
              storedComparisonApps
                .filter(app => app.game_details.item_id === appListData.item_id)
                .map((app, index) => (
                  <div key={index}>
                    <div className="d-flex justify-content-between">
                      <Button
                        onClick={() => {
                          setBtnLoader(true)
                          onAppSelect(appListData.item_id)
                        }}
                      >
                        {btnLoader ? (
                          <div className="d-flex justify-content-center">
                            <Spinner size="sm" color="white" />
                          </div>
                        ) : (
                          "Untrack App"
                        )}
                      </Button>
                      <p>
                        Last Updated:{" "}
                        {convertEpochToDate(app.game_details?.create_time)}
                      </p>
                    </div>
                    <Row className="mt-3">
                      <div>
                        <Card
                          outline
                          color="white"
                          className="border border-white"
                          style={{ width: "full" }}
                        >
                          <CardHeader className="bg-transparent border-white text-white">
                            Game Analytics
                          </CardHeader>
                          <CardBody className="p-4">
                            <div className="table-responsive">
                              <Table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <td>
                                      App Store Score :
                                      <FaInfoCircle onClick={scrollToASR} />
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.asr * 100
                                      ) / 100 || "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Keyword Relevance :
                                      <FaInfoCircle onClick={scrollToKTR} />
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.ktr * 100000
                                      ) / 100 || "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Keyword Usage:
                                      <FaInfoCircle onClick={scrollToKU} />{" "}
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.ku * 100
                                      ) / 100 || "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Downloads:
                                      <FaInfoCircle
                                        onClick={scrollToDownloads}
                                      />{" "}
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.d * 100
                                      ) / 100 || "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Average User Rating:
                                      <FaInfoCircle onClick={scrollToAur} />
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.aur * 100
                                      ) / 100 || "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      User Reviews Volume:
                                      <FaInfoCircle onClick={scrollToUrv} />
                                    </td>
                                    <td className="text-end">
                                      {Math.floor(
                                        app.game_details.analytics?.urv * 100
                                      ) / 100 || "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Total Revenue:
                                      {/* <FaInfoCircle onClick={scrollToUrv} /> */}
                                    </td>
                                    <td className="text-end">
                                      {app.game_details.analytics?.revenue
                                        ? `$ ${Number(
                                            app.game_details.analytics.revenue
                                          )
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}`
                                        : "$0.00"}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>
                    <Row className="mt-4 asrCard">
                      <div ref={asrCardRef}>
                        <Card className="">
                          <CardHeader className="bg-primary text-white">
                            App Store Score
                          </CardHeader>
                          <CardBody className="w-100 ">
                            <div>
                              <p>
                                {" "}
                                Store Ranking is based on many aspects like
                                KU,Downloads,KTR etc
                              </p>
                            </div>
                            <div className="mt-3">
                              <div className="mt-5 d-flex flex-wrap justify-content-between">
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    From
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={asrStartDate}
                                      onChange={e =>
                                        setAsrStartDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    To
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={asrEndDate}
                                      onChange={e =>
                                        setAsrEndDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div>
                                <KTRLineChart
                                  values="asr"
                                  jsonData={appHistoryData}
                                  startDate={asrStartDate}
                                  endDate={asrEndDate}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>
                    <Row className="mt-4 ktrCard">
                      <div ref={ktrCardRef}>
                        <Card className="">
                          <CardHeader className="bg-primary text-white">
                            Keyword Relevance
                          </CardHeader>
                          <CardBody className="w-100 ">
                            <div>
                              <p>
                                {" "}
                                Keyword Relevance measures how closely the app’s
                                title, description, and metadata match the
                                keywords users are searching for
                              </p>
                            </div>
                            <div className="mt-3">
                              <div className="mt-5 d-flex flex-wrap justify-content-between">
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    From
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={ktrStartDate}
                                      onChange={e =>
                                        setKtrStartDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    To
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={ktrEndDate}
                                      onChange={e =>
                                        setKtrEndDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div>
                                <KTRLineChart
                                  values="ktr"
                                  jsonData={appHistoryData}
                                  startDate={ktrStartDate}
                                  endDate={ktrEndDate}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>
                    <Row className="mt-4 kuCard">
                      <div ref={kuRef}>
                        <Card>
                          <CardHeader className="bg-primary text-white">
                            Keyword Usage
                          </CardHeader>
                          <CardBody>
                            <div>
                              <p>
                                Keyword Usage measures how well an app utilizes
                                high-ranking or relevant keywords in its
                                metadata (title, description, and tags).
                              </p>
                            </div>
                            <div className="mt-3">
                              <div className="mt-5 d-flex flex-wrap justify-content-between">
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    From
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={kuStartDate}
                                      onChange={e =>
                                        setKuStartDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    To
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={kuEndDate}
                                      onChange={e =>
                                        setKuEndDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div>
                                <KTRLineChart
                                  values="ku"
                                  jsonData={appHistoryData}
                                  startDate={kuStartDate}
                                  endDate={kuEndDate}
                                />
                              </div>
                            </div>

                            {/* <div className="pt-3">
                              <Apaexlinecolumn></Apaexlinecolumn>
                            </div> */}
                          </CardBody>
                        </Card>
                      </div>
                    </Row>
                    <Row className="mt-4 downloadCard">
                      <div ref={downRef}>
                        <Card>
                          <CardHeader className="bg-primary text-white">
                            Daily Downloads
                          </CardHeader>
                          <CardBody>
                            <div>
                              <p>
                                The number of daily downloads has a strong
                                influence on rankings. A higher download count
                                indicates a higher probability of ranking well,
                                especially if sustained over multiple days.
                              </p>
                            </div>
                            <div className="mt-3">
                              <div className="mt-5 d-flex flex-wrap justify-content-between">
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    From
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={dStartDate}
                                      onChange={e =>
                                        setDStartDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    To
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={dEndDate}
                                      onChange={e =>
                                        setDEndDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div>
                                <KTRLineChart
                                  values="d"
                                  jsonData={appHistoryData}
                                  startDate={dStartDate}
                                  endDate={dEndDate}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>

                    <Row className="mt-4 aurCard">
                      <div ref={aurRef}>
                        <Card>
                          <CardHeader className="bg-primary text-white">
                            Average User Rating
                          </CardHeader>
                          <CardBody>
                            <div>
                              <p>
                                High ratings indicate positive user sentiment,
                                which can push an app higher in rankings. App
                                stores typically prioritize apps with 4-star
                                ratings or higher.
                              </p>
                            </div>
                            <div className="mt-3">
                              <div className="mt-5 d-flex flex-wrap justify-content-between">
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    From
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={aurStartDate}
                                      onChange={e =>
                                        setAurStartDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    To
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={aurEndDate}
                                      onChange={e =>
                                        setAurEndDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div>
                                <KTRLineChart
                                  values="aur"
                                  jsonData={appHistoryData}
                                  startDate={aurStartDate}
                                  endDate={aurEndDate}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>

                    <Row className="mt-4 urvCard">
                      <div ref={urvRef}>
                        <Card>
                          <CardHeader className="bg-primary text-white">
                            User Reviews Volume
                          </CardHeader>
                          <CardBody>
                            <div>
                              <p>
                                More reviews provide credibility and user
                                validation, which can positively impact ranking.
                              </p>
                            </div>
                            <div className="mt-3">
                              <div className="mt-5 d-flex flex-wrap justify-content-between">
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    From
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={urvStartDate}
                                      onChange={e =>
                                        setUrvStartDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap gap-4">
                                  <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                  >
                                    To
                                  </label>
                                  <div className="">
                                    <input
                                      className="form-control"
                                      type="date"
                                      defaultValue={urvEndDate}
                                      onChange={e =>
                                        setUrvEndDate(e.target.value)
                                      }
                                      id="example-date-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div>
                                <KTRLineChart
                                  values="urv"
                                  jsonData={appHistoryData}
                                  startDate={urvStartDate}
                                  endDate={urvEndDate}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Row>
                  </div>
                ))
            ) : itemIds.length > 9999 ? (
              <Button color="secondary" disabled="true">
                Buy subscription to Track more
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  setBtnLoader(true)
                  onAppSelect(appid)
                }}
              >
                {btnLoader ? (
                  <div className="d-flex justify-content-center">
                    <Spinner size="sm" color="white" />
                  </div>
                ) : (
                  "Track App"
                )}
              </Button>
            )}
          </div>
        </TabPane>
      </TabContent>
      <TrackingDetailsPopup
        modaltoggle={modal_toggle}
        togglemodal={toggle_modal}
        metric={selectedMetric}
        value={metricValue}
      />
    </>
  )
}
