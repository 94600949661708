import { useState } from "react"
import { Row, Alert, Button } from "reactstrap"

const CollapsibleTrackedWords = ({
  trackedKeyword,
  selectedApp,
  removeItem,
}) => {
  const [showMore, setShowMore] = useState(false)

  const keywords = trackedKeyword[selectedApp.item_id] || []
  const visibleKeywords = showMore ? keywords : keywords.slice(0, 6) // Show 6 by default

  return (
    <Row>
      <div className="d-flex flex-wrap justify-content-start align-items-center gap-2">
        <b className="col-sm-2">Tracked Words:</b>
        {visibleKeywords.map((value, index) => (
          <div key={index} id="liveAlertPlaceholder">
            <Alert
              color="primary"
              isOpen={true}
              toggle={() => removeItem(selectedApp.item_id, value)}
            >
              {value}
            </Alert>
          </div>
        ))}
        {keywords.length > 6 && (
          <Button color="link" onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "Show More"}
          </Button>
        )}
      </div>
    </Row>
  )
}

export default CollapsibleTrackedWords
