import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"
import axios from "axios"
import { api } from "../../../utils/variables"
// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

async function registerApi(user) {
  try {
    const res = await axios.post(`${api}/user/register`, {
      email: user.email,
      password: user.password,
    })
    return res
  } catch (error) {
    console.log(error)
  }
}

function activatingID(code) {
  return axios.get(`${api}/user/verify/${code}`, {})
}
// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.password
      )
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user)
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(registerApi, user)

      // const codestring = response?.data?.code || "NA"
      // if (codestring != "NA") {
      //   const code = codestring.split("/").pop()
      //   //localStorage.setItem("code", code)

      //   //activating the id
      //   activatingID(code)
      // }

      yield put(registerUserSuccessful(response))
    }
  } catch (error) {
    // if (error.response && error.response.status === 400) {
    //   yield put(registerUserFailed("User Already Registered."))
    // } else {
    //   yield put(registerUserFailed(error))
    // }
    if (error.response && error.response.data && error.response.data.error) {
      const errorMessage = error.response.data.error // "User already exists"
      yield put(registerUserFailed(errorMessage))
    } else {
      yield put(
        registerUserFailed(error.message || "An unexpected error occurred.")
      )
    }
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
