import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import NavTabs from "../../additional_components/NavTabs"
import axios from "axios"
import { api } from "../../utils/variables"
import AppCard from "../../additional_components/marketResearch/AppCard"
import TopAppCardM from "./TopAppCardM"
export default function MetaInsights() {
  const [platform, changePlatform] = useState("all")
  const [games, setgamesresponse] = useState([])
  const [trendingGames, setTrendingGames] = useState([])
  const [newReleaseGames, setNewReleaseGames] = useState([])

  const [trendingApps, setTrendingApps] = useState([])
  const [newReleases, setNewReleases] = useState([])
  const [preOrders, setPreOrders] = useState([])
  const [mostPopular, setMostPopular] = useState([])
  const [topSelling, setTopSelling] = useState([])
  const [topEarning, setTopEarning] = useState([])

  const [itemsReleaseToShow, setItemsReleaseToShow] = useState(6)
  // Initially show 9 items

  const [showAllRelease, setShowAllRelease] = useState(false)

  const [viewMode2, setViewMode2] = useState("both") // "selling", "earning", "both"
  const [loading, setLoading] = useState(false)
  const isTrendingMeta = sessionStorage.getItem("trendingMeta")
  const isReleasedMeta = sessionStorage.getItem("releaseMeta")
  useEffect(() => {
    setLoading(true)
    fetchAll()
  }, [])

  const fetchAll = () => {
    if (isTrendingMeta || isReleasedMeta) {
      setLoading(false)
      setNewReleases(JSON.parse(isReleasedMeta))
      setTrendingApps(JSON.parse(isTrendingMeta))
    }
    try {
      fetchMostPopular()
      fetchNewReleases()
      fetchTrendingApps()
      fetchPreOrder()
      fetchTopSelling()
      fetchTopEarning()
    } finally {
    }
  }

  const fetchTrendingApps = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/trending`)
      setTrendingApps(res.data.games)
      sessionStorage.setItem("trendingMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching in trending:", error)
    } finally {
      setLoading(false)
    }
  }
  const fetchNewReleases = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/releases`)
      setNewReleases(res.data.games)
      sessionStorage.setItem("releaseMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching in releases:", error)
    }
  }

  const fetchPreOrder = async () => {
    try {
      const res = await axios.get(
        `${api}/tracker/stats/meta/coming_soon_pre_orders`
      )
      setPreOrders(res.data.games)
      sessionStorage.setItem("preOrderMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching pre order:", error)
    }
  }
  const fetchMostPopular = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/most_popular`)
      setMostPopular(res.data.games)
      sessionStorage.setItem("popularMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching most popular:", error)
    }
  }
  const fetchTopSelling = async () => {
    try {
      const res = await axios.get(
        `${api}/tracker/stats/meta/top_selling_this_week`
      )
      setTopSelling(res.data.games)
      sessionStorage.setItem("topSellingMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching top selling:", error)
    }
  }
  const fetchTopEarning = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/top_earning`)
      setTopEarning(res.data.games)
      sessionStorage.setItem("topEarningMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching top earning:", error)
    }
  }

  const handlePlatformChange = platform => {
    changePlatform(platform) // Update the platform
    const platformNewGames =
      platform !== "all"
        ? trendingApps.filter(game => game.platform === platform)
        : trendingApps // If "all", return all games
    const platformReleaseGames =
      platform !== "all"
        ? newReleases.filter(game => game.platform === platform)
        : newReleases // If "all", return all games
    setTrendingGames(platformNewGames)
    setNewReleaseGames(platformReleaseGames)
  }

  useEffect(() => {
    if (showAllRelease) setItemsReleaseToShow(trendingApps.length)
    else setItemsReleaseToShow(6)
  }, [showAllRelease, mostPopular.length]) // Re-run when "showAll" changes or data length changes

  const ITEMS_PER_SLIDE = 3
  const ITEMS_PER_SLIDE2 = 3

  const newReleaseSlides = []
  for (let i = 0; i < itemsReleaseToShow; i += 1) {
    newReleaseSlides.push(newReleases[i])
  }
  const trendingSlides = []
  for (let i = 0; i < itemsReleaseToShow; i += 1) {
    trendingSlides.push(trendingApps[i])
  }

  return (
    <React.Fragment>
      <div className="page-content m-3">
        {/* <Breadcrumb title="Top Apps" breadcrumbItem="Top Apps" /> */}
        {/* <Row className="mt-2">
          <Card>
            <CardBody>
              <Row>
                <div className="d-flex justify-content-between">
                  <NavTabs
                    onPlatformChange={handlePlatformChange}
                    tabFor=""
                  ></NavTabs>

                  <div className="d-flex flex-wrap gap-4">
                    <label
                      htmlFor="example-date-input"
                      className="col-form-label"
                    >
                      Date
                    </label>
                    <div className="">
                      <input
                        className="form-control"
                        type="date"
                        defaultValue={new Date().toISOString().split("T")[0]}
                        max={new Date().toISOString().split("T")[0]}
                        // onChange={e => setStartDate(e.target.value)}
                        id="example-date-input"
                      />
                    </div>
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Row> */}

        {/* treNDING AND new release */}
        <Row className="mt-4">
          <h4 className="fw-bold mb-3">Trending - New Release</h4>

          {/* Toggle Buttons */}
          <div className="mb-3 d-flex flex-wrap justify-content-end gap-2">
            {(viewMode2 === "both" || viewMode2 === "trending") && (
              <Button
                className="me-2"
                color={viewMode2 === "release" ? "primary" : "primary"}
                onClick={() => setViewMode2("release")}
              >
                Show New Release
              </Button>
            )}

            {(viewMode2 === "both" || viewMode2 === "release") && (
              <Button
                className="me-2"
                color={viewMode2 === "trending" ? "primary" : "primary"}
                onClick={() => setViewMode2("trending")}
              >
                Show Trending
              </Button>
            )}

            {(viewMode2 === "trending" || viewMode2 === "release") && (
              <Button
                color={viewMode2 === "both" ? "primary" : "primary"}
                onClick={() => setViewMode2("both")}
              >
                Show Both
              </Button>
            )}
          </div>

          <Table className="table table-dark mb-0">
            <thead>
              <tr className="text-center">
                <th>Sno.</th>
                {viewMode2 === "release" && (
                  <th
                    className={`${
                      viewMode2 === "release" || viewMode2 === "both"
                        ? ""
                        : "text-secondary"
                    }`}
                    colSpan={viewMode2 === "release" ? 2 : 1}
                    onClick={() => setViewMode2("release")}
                    style={{ cursor: "pointer" }}
                  >
                    New Release
                  </th>
                )}
                {viewMode2 === "trending" && (
                  <th
                    className={`${
                      viewMode2 === "trending" || viewMode2 === "both"
                        ? ""
                        : "text-secondary"
                    }`}
                    colSpan={viewMode2 === "trending" ? 2 : 1}
                    onClick={() => setViewMode2("trending")}
                    style={{ cursor: "pointer" }}
                  >
                    Trending
                  </th>
                )}
                {viewMode2 === "both" && (
                  <>
                    <th
                      className={`${
                        viewMode2 === "release" || viewMode2 === "both"
                          ? ""
                          : "text-secondary"
                      }`}
                      colSpan={viewMode2 === "release" ? 2 : 1}
                      onClick={() => setViewMode2("release")}
                      style={{ cursor: "pointer" }}
                    >
                      New Release
                    </th>
                    <th
                      className={`${
                        viewMode2 === "trending" || viewMode2 === "both"
                          ? ""
                          : "text-secondary"
                      }`}
                      colSpan={viewMode2 === "trending" ? 2 : 1}
                      onClick={() => setViewMode2("trending")}
                      style={{ cursor: "pointer" }}
                    >
                      Trending
                    </th>
                  </>
                )}

                {/* <th onClick={() => setViewMode("selling")}>Top Selling</th>
                <th onClick={() => setViewMode("earning")}>Top Earning</th> */}
              </tr>
            </thead>
            <tbody>
              {viewMode2 === "both"
                ? // ✅ Show normally when both are selected
                  newReleaseSlides.map((app, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <TopAppCardM
                          key={newReleaseSlides[index]?.item_id}
                          gamedata={newReleaseSlides[index]}
                        />
                      </td>
                      <td>
                        <TopAppCardM
                          key={trendingSlides[index]?.item_id}
                          gamedata={trendingSlides[index]}
                        />
                      </td>
                    </tr>
                  ))
                : // ✅ Odd-Even split when only one category is selected
                  newReleaseSlides
                    .filter((_, index) => index % 2 === 0) // Only take odd indexes
                    .map((app, index) => (
                      <tr key={index}>
                        <th scope="row">{index * 2 + 1}</th>
                        <td>
                          {viewMode2 === "release" && (
                            <TopAppCardM
                              key={newReleaseSlides[index * 2]?.item_id}
                              gamedata={newReleaseSlides[index * 2]}
                            />
                          )}
                          {viewMode2 === "trending" && (
                            <TopAppCardM
                              key={trendingSlides[index * 2]?.item_id}
                              gamedata={trendingSlides[index * 2]}
                            />
                          )}
                        </td>
                        <td>
                          {viewMode2 === "release" && (
                            <TopAppCardM
                              key={newReleaseSlides[index * 2 + 1]?.item_id}
                              gamedata={newReleaseSlides[index * 2 + 1]}
                            />
                          )}
                          {viewMode2 === "trending" && (
                            <TopAppCardM
                              key={trendingSlides[index * 2 + 1]?.item_id}
                              gamedata={trendingSlides[index * 2 + 1]}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
        </Row>

        {!showAllRelease && trendingApps.length > 9 ? (
          <div className="text-center mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowAllRelease(true)}
            >
              See More
            </button>
          </div>
        ) : (
          <div className="text-center mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowAllRelease(false)}
            >
              See Less
            </button>
          </div>
        )}

        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
            }}
          >
            <Spinner size="lg" color="primary" />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
