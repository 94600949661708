import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import LineChart from "../common/charts-graphs/RankDashedLine"
import BarChart from "../common/charts-graphs/BarChart"
import { Button, Input } from "reactstrap"
import AppCard from "./AppCard"
// import "mdbreact/dist/css/mdb.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "../../assets/scss/datatables.scss"
const PerformanceDataTable = ({ inputdata, gamesData }) => {
  const [sortedRows, setSortedRows] = useState([])
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  })

  // Sorting function for manual sorting component elements
  const sortRows = field => {
    const newDirection =
      sortConfig.key === field && sortConfig.direction === "asc"
        ? "desc"
        : "asc"

    const sorted = [...sortedRows].sort((a, b) => {
      if (typeof a[field] === "string") {
        return newDirection === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field])
      } else {
        return newDirection === "asc"
          ? a[field] - b[field]
          : b[field] - a[field]
      }
      return 0 //fallback case
    })

    setSortedRows(sorted)
    setSortConfig({ key: field, direction: newDirection })
  }

  const formatNumber = num => {
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + " M" // Convert to millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + " K" // Convert to thousands
    }
    return num // Return as is if less than 1000
  }

  useEffect(() => {
    if (gamesData.length > 0) {
      setSortedRows(
        gamesData.map((game, index) => {
          const ratingData = game.rating || {}
          const totalRatings = ratingData.total_rating || 0
          let overallRating = 0.0

          if (totalRatings > 0) {
            overallRating = (
              (ratingData.rating_index["1"] * 1 +
                ratingData.rating_index["2"] * 2 +
                ratingData.rating_index["3"] * 3 +
                ratingData.rating_index["4"] * 4 +
                ratingData.rating_index["5"] * 5) /
              totalRatings
            ).toFixed(1)
          }

          return {
            sno: index + 1,
            app: (
              <>
                <AppCard key={game.item_id} gamedata={game} />
                <span style={{ display: "none" }}>{game.name}</span>
              </>
            ),
            appName: game.name, // Hidden field for sorting
            keywordUsage: <p>{inputdata}</p>,
            genre: game?.genre?.join(", "),
            price: game?.price?.amount.toFixed(2) + " $",
            downloads: formatNumber(Number(game?.downloads || 0)),
            downloadsSort: Number(game?.downloads || 0),
            storeRank: "-",
            newratings: <BarChart ratingData={game.rating} />,
            newratingsdata: totalRatings > 0 ? parseFloat(overallRating) : 0, // Hidden field for sorting
            revenue:
              "$ " + formatNumber(Math.floor((game.revenue || 0) * 100) / 100),
            revenueSort: Math.floor((game.revenue || 0) * 100) / 100,
            released: game.created_at,
          }
        })
      )
    }
  }, [gamesData])

  const data = {
    columns: [
      {
        label: "Sno",
        field: "sno",
        sort: "asc",
      },
      {
        label: "App",
        field: "app",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("appName"), // Trigger manual sorting
        },
      },
      {
        label: "Keyword Usage",
        field: "keywordUsage",
        sort: "asc",
      },
      {
        label: "Genre",
        field: "genre",
        sort: "asc",
      },
      {
        label: "Price($)",
        field: "price",
        sort: "asc",
      },
      {
        label: "Estimated Downloads",
        field: "downloads",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("downloadsSort"), // Trigger manual sorting
        },
      },
      {
        label: "Store Rank",
        field: "storeRank",
        sort: "asc",
        width: 150,
      },
      {
        label: "New Ratings",
        field: "newratings",
        sort: "asc",
        attributes: { onClick: () => sortRows("newratingsdata") },
      },
      {
        label: "Revenue",
        field: "revenue",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("revenueSort"), // Trigger manual sorting
        },
      },
      {
        label: "Released",
        field: "released",
        sort: "asc",
      },

      // Add more columns here
    ],
    rows: gamesData.map((game, index) => ({
      sno: index + 1,
      app: (
        <>
          <AppCard key={game.item_id} gamedata={game} />{" "}
          <span style={{ display: "none" }}>{game.name}</span>{" "}
        </>
      ),
      appName: game.name, //hidden field holds appname

      keywordUsage: (
        <div className="d-flex flex-wrap justify-content-between gap-0">
          <p>{inputdata}</p>
        </div>
      ),
      genre: game?.genre?.join(", "),
      price: game?.price?.amount.toFixed(2) + " $",
      downloads: game?.downloads || 0,
      // (
      //   <div style={{ minWidth: "150px", minHeight: "100%" }}>

      //     <p>{game?.downloads || "N/A"}</p>
      //   </div>
      // ),

      storeRank: "-", //game.rating?.total_rating || "N/A",

      newratings: (
        <div style={{ minWidth: "150px" }}>
          <BarChart ratingData={game.rating} />
        </div>
      ),

      newratingsdata: 0,
      // revenue: Math.floor(Number(game.revenue) || 0) + " $",
      revenue: Math.floor((game.revenue || 0) * 100) / 100 + " $",

      released: game.created_at,
    })),
  }

  return (
    <MDBDataTable
      responsive
      bordered
      data={{ columns: data.columns, rows: sortedRows }}
      noBottomColumns
    />
  )
}

export default PerformanceDataTable
