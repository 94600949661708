import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  ACTIVATE_ACCOUNT,
} from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"

import { api } from "../../../utils/variables"
import axios from "axios"

const fireBaseBackend = getFirebaseBackend()

// Function to handle the API login request
function loginApi(user) {
  try {
    const res = axios.post(`${api}/user/login`, {
      email: user.email,
      password: user.password,
    })
    return res
  } catch (error) {}
}

// function activateAccount(user) {
//   try {
//     const res = axios.post(`${api}/user/activate`, {
//       email: user.email,
//     })
//     console.log(res)
//   } catch (error) {
//     console.log(error)
//   }
// }
function activateAccountApi(email) {
  return axios.post(`${api}/user/activate`, { email: email })
}

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(loginApi, {
        email: user.email,
        password: user.password,
      })
      // If login is successful, store the access token
      const accessToken = response?.data?.access_token || "NA"
      const userName = user.email.split("@")[0]
      localStorage.setItem("authToken", accessToken)
      localStorage.setItem("userName", userName)
      localStorage.setItem("userEmail", user.email)
      localStorage.setItem("authUser", JSON.stringify(response))
      console.log(JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history("/dashboard")
  } catch (error) {
    console.log(error)
    if (error.response && error.response.status === 400) {
      yield put(apiError(error.response.data.error))
    } else {
      // Handle other errors
      yield put(apiError(error))
    }
  }
}

function* activateAccountSaga({ payload }) {
  try {
    yield call(activateAccountApi, payload.email)
    // Dispatch success message
    yield put(apiError("Activation link sent to your mail")) // Ensure success message
  } catch (error) {
    yield put(apiError("Activation failed. Please try again later."))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)

      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeLatest(ACTIVATE_ACCOUNT, activateAccountSaga)
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
