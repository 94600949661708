import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumb from "../../components/Common/Breadcrumb"
import StepperProgress from "../../additional_components/common/Stepper"
import NavTabs from "../../additional_components/NavTabs"
import ProgressCard from "../../additional_components/common/ProgressCard"
//import PerformanceTable from "../additional_components/PerformanceTable"
import PerformanceDataTable from "../../additional_components/marketResearch/PerformanceDataTable"
import axios from "axios"
import { api } from "../../utils/variables"
import AppCard from "../../additional_components/marketResearch/AppCard"
import Line from "../../additional_components/Line"
import LineChart from "../../additional_components/common/charts-graphs/RankDashedLine"
import ELineChart from "../../additional_components/common/charts-graphs/E-LineChart"
import DashedLine from "../../additional_components/common/charts-graphs/RankDashedLine"
import RankedDashedLine from "../../additional_components/common/charts-graphs/RankDashedLine"
import { useParams, Link, useLocation } from "react-router-dom"
import InfoTabs from "../../additional_components/common/dashboardComponent/InfoTabs"
import ScreenShotCarousel from "../../additional_components/common/dashboardComponent/ScreenShotCarousel"
import metalogo from "../../assets/images/meta-logo.png"
import sidelogo from "../../assets/images/SideQuest-Mark-White.png"

export default function GameDashboard() {
  const [appdetail, setAppdetail] = useState([])
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const gameID = queryParams.get("id")

  // const { params } = useParams()
  const { app_id, data, selected_tab } = location.state || {}
  const [comparisonList, setComparisonList] = useState([])
  const [comparisonBetween, setComparisonBetween] = useState([])
  const storedComparisonApps = sessionStorage.getItem("comparisonBetween")
  const storedCamparisonList = sessionStorage.getItem("comparisonList")
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (gameID) {
      fetchAppDetails(gameID)
      fetchTrackedAppList()
    }
    console.log(appdetail)
  }, [gameID])

  //tracked apps

  const fetchTrackedAppList = () => {
    if (storedComparisonApps) {
      setComparisonList(JSON.parse(storedComparisonApps))
      setComparisonBetween(JSON.parse(storedComparisonApps))
    } else {
      callAppTrackapi()
    }
    callAppTrackapi()
  }

  const callAppTrackapi = async () => {
    await axios
      .get(`${api}/tracker/tracking/app`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then(res => {
        //console.log(res.data.games)
        setComparisonList(res.data.games)
        //adding data in session item
        sessionStorage.setItem(
          "comparisonBetween",
          JSON.stringify(res.data.games)
        )
      })
  }

  const fetchAppDetails = app_id => {
    //https://api.triviumpro.in/predefined/keywords
    axios.get(`${api}/tracker/app/${app_id}`, {}).then(res => {
      setAppdetail(res.data.details)
      //console.log(res.data.details)
    })
  }

  const trackNewApp = async app => {
    const appExists = comparisonList.some(
      item => item.game_details.item_id === app
    )
    if (!appExists) {
      const gameid = { item_id: app }
      try {
        const res = await axios.post(`${api}/tracker/tracking/app/`, gameid, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        console.log(res)
      } catch (error) {
        console.error("Error sending data:", error)
      } finally {
        callAppTrackapi()
      }
    } else {
      removeApp(app)
    }
  }
  async function removeApp(val) {
    // setLoading(true)
    const gameid = { item_id: val }
    try {
      const res = await axios.delete(`${api}/tracker/tracking/app.remove`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: { item_id: val }, // Adjust this key if the payload requires a different value
      })
    } catch (error) {
      console.error("Error sending data:", error)
    } finally {
      await callAppTrackapi()
    }
  }
  const ratingData = data?.rating
  const istotalratingAvailable = ratingData?.total_rating || false
  var overallRating = 0.0
  if (istotalratingAvailable) {
    overallRating = (
      (ratingData.rating_index["1"] +
        ratingData.rating_index["2"] * 2 +
        ratingData.rating_index["3"] * 3 +
        ratingData.rating_index["4"] * 4 +
        ratingData.rating_index["5"] * 5) /
      ratingData.total_rating
    ).toFixed(1)
  }
  const formatDate = dateString => {
    const date = new Date(dateString)

    // Extract individual components
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0") // getMonth() returns 0-based month
    const year = String(date.getFullYear()).slice(-2) // Get last 2 digits of the year
    let hours = date.getHours()
    const minutes = String(date.getMinutes()).padStart(2, "0")

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM"

    // Convert 24-hour format to 12-hour format
    hours = hours % 12
    hours = hours ? String(hours).padStart(2, "0") : "12" // 0 should be displayed as 12

    // Format in dd-mm-yy hh:mm AM/PM
    return `${day}-${month}-${year} ${hours}:${minutes} ${period}`
  }
  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb title="App Dashboard" breadcrumbItem="App Dashboard" />
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <Card>
                    <Row className="g-0 align-items-center">
                      <Col md={5}>
                        <CardImg
                          className="img-fluid p-2"
                          src={
                            appdetail?.image_url ||
                            "https://via.placeholder.com/50"
                          }
                          alt="image cap"
                          style={{
                            minHeight: 200,
                            maxHeight: 200,
                            minWidth: 250,
                            objectfit: "cover",
                          }}
                        />
                      </Col>
                      <Col md={7}>
                        <CardBody>
                          {/* <CardTitle className="display-1">
                            
                          </CardTitle> */}
                          {appdetail?.name ? (
                            <b className="display-6 text-white">
                              {appdetail?.name || "Demo1"}
                            </b>
                          ) : (
                            <b className="placeholder-glow">
                              <span className="placeholder col-3"></span>
                            </b>
                          )}

                          <CardText>
                            {appdetail?.category || "category"}
                          </CardText>
                          <CardText>
                            <div className="d-flex justify-content-start gap-4">
                              <a
                                href={appdetail?.website_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {appdetail?.publisher || "publisher"}
                              </a>
                              <Link
                                to={`/publisherapps?item_id=${appdetail?.item_id}`}
                              >
                                <p>
                                  All games from:{" "}
                                  {appdetail?.publisher || "publisher"}
                                </p>
                              </Link>
                            </div>
                          </CardText>

                          <div className="d-flex justify-content-start gap-1">
                            <a
                              href={appdetail?.platform_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {appdetail?.platform == "meta" ? (
                                <img
                                  src={metalogo}
                                  className="rounded avatar-sm align-self-center"
                                  alt=""
                                  style={{
                                    minHeight: 30,
                                    maxHeight: 30,
                                    minWidth: 40,
                                    maxWidth: 40,
                                  }}
                                />
                              ) : appdetail.platform == "sidequest" ? (
                                <img
                                  src={metalogo}
                                  className="rounded avatar-sm align-self-center"
                                  alt=""
                                  style={{
                                    minHeight: 30,
                                    maxHeight: 30,
                                    minWidth: 40,
                                    maxWidth: 40,
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </a>
                            <small className="text-muted align-self-center">
                              {appdetail?.created_at
                                ? `Last updated ${formatDate(
                                    appdetail?.created_at
                                  )}`
                                : "Last updated -"}
                            </small>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                {/* <Col lg={3}>
                  <Card
                    color="info"
                    className="border-info text-white"
                    style={{ width: "100%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Category</CardTitle>
                      <p className="card-title-desc">
                        {appdetail?.category || "NA"}
                      </p>
                    </CardBody>
                  </Card>
                </Col> */}
                <Col lg={3}>
                  <Card
                    color="info"
                    className="border-info text-white"
                    style={{ width: "100%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Price</CardTitle>
                      <p className="card-title-desc">
                        {appdetail.price?.amount +
                          " " +
                          appdetail.price?.currency}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card
                    color="info"
                    className="border-info text-white"
                    style={{ width: "100%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Downloads</CardTitle>
                      <p className="card-title-desc">
                        {appdetail?.downloads || "NA"}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card
                    color="info"
                    className="border-info text-white"
                    style={{ width: "100%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Ratings</CardTitle>
                      <p className="card-title-desc">{overallRating}</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}>
                  <Card
                    color="info"
                    className="border-info text-white"
                    style={{ width: "100%", minWidth: "140px" }}
                  >
                    <CardBody>
                      <CardTitle className="h4">Revenue</CardTitle>
                      <p className="card-title-desc">
                        {appdetail?.revenue
                          ? `$ ${Number(appdetail.revenue)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                          : "$0.00"}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <InfoTabs
                appid={gameID}
                appListData={appdetail}
                trackedApp={comparisonList}
                selectedTab={selected_tab}
                onAppSelect={trackNewApp}
              ></InfoTabs>
            </CardBody>
          </Card>
        </Row>
      </div>

      {/* Loading Modal */}
    </React.Fragment>
  )
}
