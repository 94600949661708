import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"

// Redux
import { Link, useParams } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import {
  loginUser,
  socialLogin,
  activateAccount,
  apiError,
} from "../../store/actions"

// import images
//import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logo from "../../assets/images/brands/trivium logo.jpg"
import logodark from "../../assets/images/trivlogo-sm.png"
//Import config
import { facebook, google } from "../../config"
import { api } from "../../utils/variables"
import axios from "axios"

const Login = props => {
  document.title = " Login | Trivium"

  const { token } = useParams()
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "norexe1389@cironex.com" || "",
      // password: "qwerty123" || "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      // const username = values.email.split('@')[0];
      // console.log(values.email)
      dispatch({ type: "LOGIN_ERROR_CLEAR" })
      dispatch(loginUser(values, props.router.navigate)).finally(() => {
        setLoginLoading(false) // Stop loading
      })
    },
    // onSubmit: async values => {
    //   try {
    //     // Make the API call to login
    //     const response = await axios.post(`${api}/user/login`, {
    //       email: values.email,
    //       password: values.password,
    //     })
    //     console.log(api)
    //     console.log(response)
    //     // Get the token from the response and store it in sessionStorage
    //     const accessToken = response.data.access_token
    //     sessionStorage.setItem("accessToken", accessToken)

    //     // Redirect to the dashboard or another authenticated page
    //     props.router.navigate("/dashboard")
    //   } catch (error) {
    //     console.error("Login failed", error)
    //     alert("Invalid login credentials.")
    //   }
    // },
  })
  const [loginLoading, setLoginLoading] = useState(false)
  const [showActivateButton, setShowActivateButton] = useState(false)
  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))

  const { error } = useSelector(LoginProperties)

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.router.navigate, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.router.navigate, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  const handleActivateAccount = () => {
    dispatch(apiError(null)) // Clear existing error
    dispatch(activateAccount(validation.values.email))
  }

  useEffect(() => {
    document.body.className = "authentication-bg"
    // remove classname when component will unmount

    return function cleanup() {
      document.body.className = ""
    }
  })
  useEffect(() => {
    if (error) {
      setLoginLoading(false)
      //
    }
    if (error && error === "User is not active. Please activate your account") {
      setShowActivateButton(true)
    } else if (
      error &&
      error !== "User is not active. Please activate your account"
    ) {
      setShowActivateButton(false) // Hide activate button if error is cleared or different
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <Link to="/" className="mb-3 d-block auth-logo">
                          {/* <img src={logo} alt="" height="22" className="logo logo-dark" />*/}

                          <img
                            src={logodark}
                            alt=""
                            height="60"
                            className="logo logo-dark"
                          />
                          {/* <h2>Trivium</h2> */}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">
                      Sign in to continue to Trivium.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        setLoginLoading(true)
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : <></>}

                      {showActivateButton && (
                        <button
                          type="button"
                          className="btn btn-danger w-100 waves-effect waves-light mb-3"
                          onClick={handleActivateAccount}
                        >
                          Activate Account
                        </button>
                      )}
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div>
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        {loginLoading == true ? (
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            <Spinner color="white" />
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        )}
                        {/* <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button> */}
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          {google.CLIENT_ID !== "" && (
                            <li className="list-inline-item">
                              <GoogleLogin
                                clientId={google.CLIENT_ID}
                                render={renderProps => (
                                  <Link
                                    to="#"
                                    className="social-list-item bg-danger text-white border-danger"
                                    onClick={renderProps.onClick}
                                  >
                                    <i className="mdi mdi-google" />
                                  </Link>
                                )}
                                onSuccess={googleResponse}
                                onFailure={() => {}}
                              />
                            </li>
                          )}
                        </ul>
                      </div> */}

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Don&apos;t have an account ?{" "}
                          <a
                            href="/register"
                            className="fw-medium text-primary"
                          >
                            {" "}
                            Signup now{" "}
                          </a>{" "}
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
