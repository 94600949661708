import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
// import "mdbreact/dist/css/mdb.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "../../../assets/scss/datatables.scss"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { isNumber } from "lodash"
import { FaAngleDoubleDown, FaAngleDoubleUp, FaEquals } from "react-icons/fa"

const CategoryTrackingDataTable = ({ inputdata, categoryData }) => {
  const [modal_toggle, setmodal_toggle] = useState(false)
  const toggle_modal = () => {
    setmodal_toggle(prev => !prev)
  }

  const gameData = categoryData?.game_data
  const [topApps, setTopApps] = useState({})
  const [selectedCategory, setSelectedCategory] = useState("")
  const [sortedRows, setSortedRows] = useState([])
  const [created, setCreated] = useState(null)
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  })

  function convertEpochToDate(epochTime) {
    const date = new Date(epochTime * 1000) // Multiply by 1000 to convert from seconds to milliseconds

    const day = String(date.getDate()).padStart(2, "0") // Get day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0") // Get month (0-based, so add 1)
    const year = date.getFullYear().toString().slice(-4) // Get last 2 digits of the year
    const hours = String(date.getHours()).padStart(2, "0") // Get hours
    const minutes = String(date.getMinutes()).padStart(2, "0") // Get minutes

    // Format the date as dd-mm-yy hh:mm
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`

    return formattedDate
  }

  const sortRows = field => {
    const newDirection =
      sortConfig.key === field && sortConfig.direction === "asc"
        ? "desc"
        : "asc"

    const sorted = [...sortedRows].sort((a, b) => {
      if (typeof a[field] === "string") {
        return newDirection === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field])
      } else {
        return newDirection === "asc"
          ? a[field] - b[field]
          : b[field] - a[field]
      }
      return 0 //fallback case
    })

    setSortedRows(sorted)
    setSortConfig({ key: field, direction: newDirection })
  }

  useEffect(() => {
    if (Object.keys(categoryData).length > 0) {
      setSortedRows(
        Object.values(categoryData)
          .slice(0, -1)
          ?.map((category, index) => {
            if (index == 0) {
              setCreated(category?.created_at)
            }
            const historicalData = category?.matching_historical_data
            const gameType = historicalData?.at(-1)?.platform

            const lastRank =
              historicalData?.length > 0 &&
              historicalData?.at(-1)?.ranks[category?.game]
                ? Number(historicalData.at(-1).ranks[category?.game])
                : gameType === "meta"
                ? "> 10"
                : "> 30"

            const secondLastRank =
              historicalData?.length > 0 &&
              historicalData?.at(-2)?.ranks[category?.game]
                ? Number(historicalData.at(-2).ranks[category?.game])
                : gameType === "meta"
                ? "> 10"
                : "> 30"

            let rankChange = "No Change"
            if (
              lastRank !== "> 30" &&
              secondLastRank !== "> 30" &&
              lastRank !== "> 10" &&
              secondLastRank !== "> 10"
            ) {
              rankChange = lastRank - secondLastRank
              rankChange =
                rankChange > 0
                  ? `+${rankChange}` // Positive change
                  : `${rankChange}` // Negative change
            }
            if (historicalData.length > 0)
              return {
                Category: category?.category,
                Position: lastRank,
                cPos: isNumber(lastRank) ? lastRank : 99999,
                LastPosition: secondLastRank,
                lPos: isNumber(secondLastRank) ? secondLastRank : 99999,
                Change:
                  rankChange == "No Change" ? (
                    rankChange
                  ) : rankChange < 0 ? (
                    <>
                      <FaAngleDoubleUp color="green" />
                      <span className="align-middle">{rankChange}</span>
                    </>
                  ) : rankChange == 0 ? (
                    <>
                      <FaEquals color="yellow" />
                      <span className="align-middle"> {rankChange}</span>
                    </>
                  ) : (
                    <>
                      <FaAngleDoubleDown color="red" />
                      <span className="align-middle"> {rankChange}</span>
                    </>
                  ),
                changePos:
                  rankChange == "No Change"
                    ? 99999
                    : Number(lastRank - secondLastRank),
                Popularity: "-",
                Competitiveness: "-", // Last entry,
                Topapps: (
                  <Button
                    color="primary"
                    onClick={() => {
                      toggle_modal()
                      setTopApps(
                        category?.matching_historical_data?.at(-1)?.ranks
                      )
                      setSelectedCategory(category?.category)
                      console.log(gameData)
                    }}
                  >
                    Top Ranks
                  </Button>
                ),
              }
            else {
              return {
                Category: category?.category,
                Position: "loading..",
                LastPosition: "loading...",
                lPos: isNumber(secondLastRank) ? secondLastRank : 99999,
                Change: "loading..", //hidden field holds appname
                Popularity: "-",
                Competitiveness: "-", // Last entry,
                Topapps: (
                  <Button
                    color="secondary"
                    disabled="true"
                    onClick={() => {
                      toggle_modal()
                      setTopApps(
                        category?.matching_historical_data?.at(-1)?.ranks
                      )
                      setSelectedCategory(category?.category)
                      console.log(gameData)
                    }}
                  >
                    Top Ranks
                  </Button>
                ),
              }
            }
          })
      )
    }
  }, [categoryData])

  const data = {
    columns: [
      {
        label: "Category",
        field: "Category",
        sort: "asc",
      },
      {
        label: "Current Position",
        field: "Position",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("cPos"), // Trigger manual sorting
        },
      },
      {
        label: "Last Position",
        field: "LastPosition",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("lPos"), // Trigger manual sorting
        },
      },
      {
        label: "Change",
        field: "Change",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("changePos"), // Trigger manual sorting
        },
      },
      {
        label: "Popularity",
        field: "Popularity",
        sort: "asc",
      },
      {
        label: "Competitiveness",
        field: "Competitiveness",
        sort: "asc",
      },
      {
        label: "Top Ranks",
        field: "Topapps",
        sort: "asc",
      },

      //   {
      //     label: "Released",
      //     field: "released",
      //     sort: "asc",
      //   },

      // Add more columns here
    ],
    rows: Object.values(categoryData)
      .slice(0, -1)
      ?.map((category, index) => {
        const historicalData = category?.matching_historical_data
        const gameType = historicalData?.at(-1)?.platform

        const lastRank =
          historicalData?.length > 0 &&
          historicalData?.at(-1)?.ranks[category?.game]
            ? Number(historicalData.at(-1).ranks[category?.game])
            : gameType === "meta"
            ? "> 10"
            : "> 30"

        const secondLastRank =
          historicalData?.length > 0 &&
          historicalData?.at(-2)?.ranks[category?.game]
            ? Number(historicalData.at(-2).ranks[category?.game])
            : gameType === "meta"
            ? "> 10"
            : "> 30"

        let rankChange = "No Change"
        if (
          lastRank !== "> 30" &&
          secondLastRank !== "> 30" &&
          lastRank !== "> 10" &&
          secondLastRank !== "> 10"
        ) {
          rankChange = lastRank - secondLastRank
          rankChange =
            rankChange > 0
              ? `+${rankChange}` // Positive change
              : `${rankChange}` // Negative change
        }
        if (historicalData.length > 0)
          return {
            Category: category?.category,
            Position: lastRank,
            cPos: isNumber(lastRank) ? lastRank : -1,
            LastPosition: secondLastRank,
            Change: rankChange, //hidden field holds appname
            Popularity: "-",
            Competitiveness: "-", // Last entry,
            Topapps: (
              <Button
                color="primary"
                onClick={() => {
                  toggle_modal()
                  setTopApps(category?.matching_historical_data?.at(-1)?.ranks)
                  setSelectedCategory(category?.category)
                  console.log(gameData)
                }}
              >
                Top Ranks
              </Button>
            ),
          }
        else {
          return {
            Category: category?.category,
            Position: "loading..",
            LastPosition: "loading...",
            Change: "loading..", //hidden field holds appname
            Popularity: "-",
            Competitiveness: "-", // Last entry,
            Topapps: (
              <Button
                color="secondary"
                disabled="true"
                onClick={() => {
                  toggle_modal()
                  setTopApps(category?.matching_historical_data?.at(-1)?.ranks)
                  setSelectedCategory(category?.category)
                  console.log(gameData)
                }}
              >
                Top Ranks
              </Button>
            ),
          }
        }
      }),
  }

  return (
    <>
      <p>Created at: {convertEpochToDate(created)}</p>
      <MDBDataTable
        responsive
        bordered
        data={{ columns: data.columns, rows: sortedRows }}
        noBottomColumns
      />

      <Modal
        isOpen={modal_toggle}
        toggle={() => {
          toggle_modal()
        }}
        centered
        size="md"
        style={{ maxHeight: "100vh" }}
      >
        <div className="modal-header bg-gradient-primary">
          <div className="d-flex justify-content-start gap-2 align-items-center">
            {/* <FaLock></FaLock> */}
            <h5 className="modal-title" id="staticBackdropLabel">
              Top Ranks in {selectedCategory}
            </h5>
          </div>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              toggle_modal()
            }}
            aria-label="Close"
          ></button>
        </div>
        <ModalBody
          className=""
          style={{
            maxHeight: "80vh",
            // minHeight: "40vh",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          <div>
            <Row className="pb-2">
              <Col lg={12}>
                <Card className="mb-1">
                  {topApps && Object.keys(topApps).length === 0 ? (
                    <p>NO DATA</p>
                  ) : topApps ? (
                    Object.entries(topApps)
                      .sort(([, rankA], [, rankB]) => rankA - rankB) // Sort by rank in ascending order
                      .map(([app, rank], index) => {
                        const gameDetails = gameData.find(
                          game => game.item_id === app
                        ) // Find game by item_id
                        return (
                          <Row className="g-0 align-items-center" key={index}>
                            <Col md={4}>
                              <CardImg
                                className="img-fluid p-2"
                                src={
                                  gameDetails?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 70,
                                  maxHeight: 70,
                                  minWidth: 120,
                                  maxWidth: 120,
                                  objectFit: "cover",
                                }}
                              />
                            </Col>
                            <Col md={8}>
                              <CardBody>
                                <div className="d-flex justify-content-between">
                                  <b className="text-white">
                                    {gameDetails?.name || "Demo"}
                                  </b>
                                  <p>{rank}</p>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        )
                      })
                  ) : (
                    <p>Loading...</p>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CategoryTrackingDataTable
