import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useLocation } from "react-router-dom"

// Function to convert "dd-mm-yy" to a standard Date object
const convertToDate = dateStr => {
  const [year, month, day] = dateStr.split("-")
  return new Date(`${year}`, month - 1, day) // Adjusting for year and month being 0-indexed
}

// Function to generate dates between start and end date
const generateDateRange = (start, end) => {
  const startDate = convertToDate(start)
  const endDate = convertToDate(end)
  const dates = []

  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    const formattedDate = `${String(date.getDate()).padStart(
      2,
      "0"
    )}-${date.toLocaleString("default", {
      month: "short",
    })}-${date.getFullYear()}`
    dates.push(formattedDate)
  }

  return dates
}
const adjustDataToDateRange = (data, dateRangeLength) => {
  if (data?.length > dateRangeLength) {
    // Truncate data to match the length of the date range
    return data?.slice(0, dateRangeLength)
  } else {
    // Extend data with null or placeholders to match the length of the date range
    return [...data, ...Array(dateRangeLength - data?.length).fill(null)]
  }
}

// Utility function to round a number to two decimal places
const roundToTwoDecimals = number => {
  return Math.round(number * 100) / 100
}

// Utility function to round a number to three decimal places
const roundToThreeDecimals = number => {
  return Math.round(number * 1000) / 1000
}

// Utility function to round a number to two decimal places
const roundToInteger = number => Math.floor(number)

// Map data according to the date range
const mapDataToDates = (value, jsonData, dateRange) => {
  // Create a map of date to ktr value, normalizing the created_at_date to "dd-MMM-yyyy"
  const dateToValueMap = new Map(
    jsonData?.map(entry => {
      // Extract the date part and format as "dd-MMM-yyyy"
      const rawDate = new Date(entry.created_at_date)
      const formattedDate = `${String(rawDate.getDate()).padStart(
        2,
        "0"
      )}-${rawDate.toLocaleString("default", {
        month: "short",
      })}-${rawDate.getFullYear()}`

      switch (value) {
        case "ktr":
          return [formattedDate, roundToTwoDecimals(entry[value] * 1000)]
        case "asr":
          return [formattedDate, roundToInteger(entry[value])]
        case "ku":
          return [formattedDate, roundToInteger(entry[value])]
        case "d":
          return [formattedDate, entry.d]
        case "aur":
          return [formattedDate, roundToTwoDecimals(entry[value])]
        case "urv":
          return [formattedDate, entry[value]]
      }
      return [formattedDate, roundToTwoDecimals(entry[value])]
    })
  )

  // Map the dateRange to corresponding ktr values or null
  return dateRange?.map(date => dateToValueMap.get(date) || null)
}

const KTRLineChart = ({ values, jsonData, startDate, endDate }) => {
  const location = useLocation()
  // const { params } = useParams()
  const { app_id, data, selected_tab } = location.state || {}
  const [xAxisCategories, setXAxisCategories] = useState([])
  const [topGrossingData, setTopGrossingData] = useState([])
  const [freeRankData, setFreeRankData] = useState([])
  const baseTopGross = jsonData?.map(entry => entry[values])
  const [dataFor, setDataFor] = useState("value")
  // const baseFreeRank = [
  //   36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35, 26, 23, 22, 8, 9, 36, 42,
  //   60, 42, 13, 18, 29, 37, 36, 51, 32, 35, 26, 23, 22, 8, 9,
  // ]

  const series = [
    {
      name: dataFor,

      data: topGrossingData,
    },
  ]

  const colorMapping = {
    ktr: "#5b73e8", // Example color for 'ktr'
    asr: "#34c38f", // Example color for 'asr'
    d: "#30accf",
    aur: "#e83e8c",
    urv: "#f46a6a",
    other: "#f1b44c", // Default or other value
  }
  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: [colorMapping[values] || colorMapping.other, "#f1b44c"],
    //"#f1b44c"
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 4, 3],
      curve: "straight",
      dashArray: [0, 8, 5],
    },
    title: {},
    markers: {
      size: 0,

      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: xAxisCategories,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session"
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }

  // Update x-axis whenever startDate or endDate changes
  useEffect(() => {
    setDataFor(values)
    // console.log(baseTopGross)
    // console.log(`startdTE:${startDate} and the endDate :${endDate}`)
    const newCategories = generateDateRange(startDate, endDate)
    setXAxisCategories(newCategories)

    // Map jsonData to the date range
    const alignedTopGrossingData = mapDataToDates(
      values,
      jsonData,
      newCategories
    )

    setTopGrossingData(alignedTopGrossingData)
    //setFreeRankData(adjustDataToDateRange(baseFreeRank, newCategories.length))
  }, [startDate, endDate, jsonData])

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
      className="apex-charts"
    />
  )
}

export default KTRLineChart
