import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import LineChart from "../common/charts-graphs/RankDashedLine"
import BarChart from "../common/charts-graphs/BarChart"
import { Button, Input } from "reactstrap"
import AppCard from "./AppCard"
// import "mdbreact/dist/css/mdb.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "../../assets/scss/datatables.scss"
import SmallAppCard from "./categoryAnalysis/SmallAppCard"
import StarRatings from "./categoryAnalysis/StartRatings"
const CategoryDataTable = ({ inputdata, gamesData }) => {
  const [sortedRows, setSortedRows] = useState([])
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  })

  // Sorting function for manual sorting component elements
  const sortRows = field => {
    const newDirection =
      sortConfig.key === field && sortConfig.direction === "asc"
        ? "desc"
        : "asc"

    const sorted = [...sortedRows].sort((a, b) => {
      if (typeof a[field] === "string") {
        return newDirection === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field])
      } else {
        return newDirection === "asc"
          ? a[field] - b[field]
          : b[field] - a[field]
      }
      return 0 //fallback case
    })

    setSortedRows(sorted)
    setSortConfig({ key: field, direction: newDirection })
  }
  const formatNumber = num => {
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + " M" // Convert to millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + " K" // Convert to thousands
    }
    return num // Return as is if less than 1000
  }
  useEffect(() => {
    if (gamesData.length > 0) {
      setSortedRows(
        gamesData.map((game, index) => {
          const ratingData = game.rating || {}
          const totalRatings = ratingData.total_rating || 0
          let overallRating = 0.0

          if (totalRatings > 0) {
            overallRating = (
              (ratingData.rating_index["1"] * 1 +
                ratingData.rating_index["2"] * 2 +
                ratingData.rating_index["3"] * 3 +
                ratingData.rating_index["4"] * 4 +
                ratingData.rating_index["5"] * 5) /
              totalRatings
            ).toFixed(1)
          }

          return {
            sno: index + 1,
            app: <SmallAppCard key={game._id} gamedata={game} />,
            appNameSearch: game.name, //hidden field holds appname
            category: game?.category || inputdata,
            subcategory: (
              <div style={{ maxWidth: "250px" }}>{game?.genre?.join(", ")}</div>
            ),
            subcatSorting: game?.genre?.join(", "),
            price: game?.price?.amount.toFixed(2) + " $",
            downloads: formatNumber(Number(game?.downloads || 0)),
            downloadssort: Number(game?.downloads || 0),
            ratings: <StarRatings ratingData={game.rating} />,
            newratingsdata: totalRatings > 0 ? parseFloat(overallRating) : 0, // Hidden field for sorting
            revenue: "$ " + formatNumber(Math.floor(game.revenue || 0)),
            revenueSort: Math.floor(game.revenue || 0),
            updated: game.updated_at,
          }
        })
      )
    }
  }, [gamesData])
  const data = {
    columns: [
      {
        label: "Sno",
        field: "sno",
        sort: "asc",
      },
      {
        label: "App",
        field: "app",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("appNameSearch"), // Trigger manual sorting
        },
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
      },
      {
        label: "Sub Category",
        field: "subcategory",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("subcatSorting"), // Trigger manual sorting
        },
      },
      {
        label: "Price($)",
        field: "price",
        sort: "asc",
        width: 100,
      },
      {
        label: "Ratings",
        field: "ratings",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("newratingsdata"), // Trigger manual sorting
        },
      },
      {
        label: "Estimated Downloads",
        field: "downloads",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("downloadssort"), // Trigger manual sorting
        },
      },

      {
        label: "Revenue",
        field: "revenue",
        sort: "asc",
        attributes: {
          onClick: () => sortRows("revenueSort"), // Trigger manual sorting
        },
      },
      {
        label: "Updated",
        field: "updated",
        sort: "asc",
      },

      // Add more columns here
    ],
    rows: gamesData?.map((game, index) => ({
      sno: index + 1,
      app: <SmallAppCard key={game._id} gamedata={game} />,
      appNameSearch: game.name, //hidden field holds appname
      category: game?.category || inputdata,
      subcategory: (
        <div style={{ maxWidth: "250px" }}>{game?.genre?.join(", ")}</div>
      ),
      subcatSorting: game?.genre?.join(", "),
      price: game?.price?.amount,
      downloads: Number(game?.downloads || 0),

      ratings: <StarRatings ratingData={game.rating} />,
      newratingsdata: 0, // Hidden field for sorting

      revenue: Math.floor((game.revenue || 0) * 100) / 100 + " $",
      updated: game.created_at,
    })),
  }

  return (
    <MDBDataTable
      responsive
      bordered
      data={{ columns: data.columns, rows: sortedRows }}
      noBottomColumns
    />
  )
}

export default CategoryDataTable
