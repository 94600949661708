import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import NavTabs from "../../additional_components/NavTabs"
import axios from "axios"
import { api } from "../../utils/variables"
import AppCard from "../../additional_components/marketResearch/AppCard"
import TopAppCardM from "./TopAppCardM"
import TopAppCardSmall from "./TopAppCardSmall"
export default function Popular() {
  const [platform, changePlatform] = useState("all")
  const [games, setgamesresponse] = useState([])
  const [trendingGames, setTrendingGames] = useState([])
  const [newReleaseGames, setNewReleaseGames] = useState([])

  const [trendingApps, setTrendingApps] = useState([])
  const [newReleases, setNewReleases] = useState([])
  const [preOrders, setPreOrders] = useState([])
  const [mostPopular, setMostPopular] = useState([])
  const [topSelling, setTopSelling] = useState([])
  const [topEarning, setTopEarning] = useState([])
  const [itemsToShow, setItemsToShow] = useState(9)
  const [itemsPoToShow, setItemsPoToShow] = useState(9)

  // Initially show 9 items
  const [showAll, setShowAll] = useState(false) // Flag to show all items
  const [showAllPO, setShowAllPO] = useState(false)

  const [loading, setLoading] = useState(false)

  const isPopular = sessionStorage.getItem("popularMeta")
  const isPreOrder = sessionStorage.getItem("preOrderMeta")

  useEffect(() => {
    setLoading(true)
    fetchAll()
  }, [])

  const fetchAll = () => {
    if (isPopular || isPreOrder) {
      setLoading(false)
      setPreOrders(JSON.parse(isPreOrder))
      setMostPopular(JSON.parse(isPopular))
    }
    try {
      fetchMostPopular()
      fetchNewReleases()
      fetchTrendingApps()
      fetchPreOrder()
      fetchTopSelling()
      fetchTopEarning()
    } finally {
    }
  }

  const fetchTrendingApps = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/trending`)
      setTrendingApps(res.data.games)
      sessionStorage.setItem("trendingMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching in trending:", error)
    } finally {
      setLoading(false)
    }
  }
  const fetchNewReleases = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/releases`)
      setNewReleases(res.data.games)
      sessionStorage.setItem("releaseMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching in releases:", error)
    }
  }

  const fetchPreOrder = async () => {
    try {
      const res = await axios.get(
        `${api}/tracker/stats/meta/coming_soon_pre_orders`
      )
      setPreOrders(res.data.games)
      sessionStorage.setItem("preOrderMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching pre order:", error)
    }
  }
  const fetchMostPopular = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/most_popular`)
      setMostPopular(res.data.games)
      sessionStorage.setItem("popularMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching most popular:", error)
    }
  }
  const fetchTopSelling = async () => {
    try {
      const res = await axios.get(
        `${api}/tracker/stats/meta/top_selling_this_week`
      )
      setTopSelling(res.data.games)
      sessionStorage.setItem("topSellingMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching top selling:", error)
    }
  }
  const fetchTopEarning = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/top_earning`)
      setTopEarning(res.data.games)
      sessionStorage.setItem("topEarningMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching top earning:", error)
    }
  }

  const handlePlatformChange = platform => {
    changePlatform(platform) // Update the platform
    const platformNewGames =
      platform !== "all"
        ? trendingApps.filter(game => game.platform === platform)
        : trendingApps // If "all", return all games
    const platformReleaseGames =
      platform !== "all"
        ? newReleases.filter(game => game.platform === platform)
        : newReleases // If "all", return all games
    setTrendingGames(platformNewGames)
    setNewReleaseGames(platformReleaseGames)
  }

  useEffect(() => {
    if (showAll) {
      setItemsToShow(mostPopular.length) // If "See More" is clicked, show all items
    } else {
      setItemsToShow(6) // If not clicked, show the first 9 items
    }

    if (showAllPO) setItemsPoToShow(preOrders.length)
    else setItemsPoToShow(6)
  }, [showAll, showAllPO, mostPopular.length]) // Re-run when "showAll" changes or data length changes

  const ITEMS_PER_SLIDE = 3
  const ITEMS_PER_SLIDE2 = 4
  // Group items into slides based on how many we want to show
  const slides = []
  for (let i = 0; i < itemsToShow; i += ITEMS_PER_SLIDE) {
    slides.push(mostPopular.slice(i, i + ITEMS_PER_SLIDE))
  }

  const preOrderSlides = []
  for (let i = 0; i < itemsPoToShow; i += ITEMS_PER_SLIDE2) {
    preOrderSlides.push(preOrders.slice(i, i + ITEMS_PER_SLIDE2))
  }

  return (
    <React.Fragment>
      <div className="page-content m-3">
        {/* <Breadcrumb title="Top Apps" breadcrumbItem="Top Apps" /> */}

        {/* Most Popular */}
        <Row className="mt-4">
          <h4 className="fw-bold mb-3">Most Popular</h4>
          {slides.map((group, index) => (
            <Row>
              {group.map((game, ind) => (
                <Col className="col-sm">
                  <div
                    key={index * ITEMS_PER_SLIDE + ind + 1}
                    className="d-flex align-items-center bg-light p-3 rounded shadow-sm mb-3"
                  >
                    <span className="fw-bold fs-4 me-3">
                      {index * ITEMS_PER_SLIDE + ind + 1}
                    </span>

                    <TopAppCardM
                      key={game?.item_id}
                      gamedata={game}
                    ></TopAppCardM>
                  </div>
                </Col>
              ))}
            </Row>
          ))}
        </Row>
        {!showAll && mostPopular.length > 9 ? (
          <div className="text-center mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowAll(true)}
            >
              See More
            </button>
          </div>
        ) : (
          <div className="text-center mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowAll(false)}
            >
              See Less
            </button>
          </div>
        )}
        <hr></hr>
        {/* Pre Orders */}
        <Row className="mt-4">
          <h4 className="fw-bold mb-3">Pre Orders</h4>
          {preOrderSlides.map((group, index) => (
            <Row>
              {group.map((game, ind) => (
                <Col className="col-sm">
                  {/* <div
                    key={index * ITEMS_PER_SLIDE2 + ind + 1}
                    className="d-flex align-items-center bg-light p-3 rounded shadow-sm mb-3"
                  > */}
                  {/* <span className="fw-bold fs-4 me-3">
                      {index * ITEMS_PER_SLIDE2 + ind + 1}
                    </span> */}
                  <TopAppCardSmall
                    key={game?.item_id}
                    gamedata={game}
                  ></TopAppCardSmall>
                  {/* </div> */}
                </Col>
              ))}
            </Row>
          ))}
        </Row>
        {!showAllPO && preOrders.length > 9 ? (
          <div className="text-center mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowAllPO(true)}
            >
              See More
            </button>
          </div>
        ) : (
          <div className="text-center mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowAllPO(false)}
            >
              See Less
            </button>
          </div>
        )}

        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
            }}
          >
            <Spinner size="lg" color="primary" />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
