import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumb from "../components/Common/Breadcrumb"
import StepperProgress from "../additional_components/common/Stepper"
import NavTabs from "../additional_components/NavTabs"
import ProgressCard from "../additional_components/common/ProgressCard"
//import PerformanceTable from "../additional_components/PerformanceTable"
// import PerformanceDataTable from "../additional_components/marketResearch/PerformanceDataTable"
import axios from "axios"
import { api, token } from "../utils/variables"
import PerformanceDataTable from "../additional_components/marketResearch/PerformanceDataTable"
export default function MarketResearch() {
  const [value, setValue] = useState("")
  const [inputValue, setInputValue] = useState("")
  const [modal_toggle, setmodal_toggle] = useState(false)
  const [second_modal_toggle, setsecond_modal_toggle] = useState(false)
  const [games, setgamesresponse] = useState([])
  const [filteredGames, setFilteredGames] = useState([])
  const [selectedPlatform, changePlatform] = useState("all")
  const [dataAction, setDataAction] = useState("loading")
  const apiInstance = axios.create({
    baseURL: `${api}`, // Replace with your actual base URL
    // headers: {
    //   Authorization: `Bearer ${token}`, // Set the token here
    // },
  })
  const [predefKeyword, setpredef] = useState([])
  const storedgamesAppsData = sessionStorage.getItem("games")

  const funFacts = [
    "In the early '90s, Virtuality introduced arcade VR systems. While the graphics weren’t great by today’s standards, it was a huge leap forward in immersive gaming.",
    "VR has been used for therapy, helping people confront their fears safely. Whether it's flying, heights, or spiders, VR lets you face your phobia in a controlled virtual environment.",
    "Games like Beat Saber and The Thrill of the Fight give you a great cardio workout. You’re not just sitting there—you’re actively moving and getting your heart rate up!",
    "Titles like BoxVR make exercise fun by turning it into a boxing simulation. You can burn calories while feeling like a badass in the process.",
    "NASA uses VR to help astronauts practice in space-like environments, simulating zero gravity, equipment repair, and mission procedures before they head off to the stars.",
    "Not just for gaming—apps like Bigscreen let you watch movies with friends in a virtual theater. It’s a totally immersive social experience.",
    "The first VR headsets were clunky and uncomfortable. The Virtual Boy, for example, was so heavy that it was hard to use for more than a short period!",
    "VR is used in mental health therapy, offering calming environments or stress-relieving experiences. It’s even being tested for things like anxiety and PTSD treatment.",
  ]

  const [randomFact, setRandomFact] = useState("")

  useEffect(() => {
    setRandomFact(funFacts[Math.floor(Math.random() * funFacts.length)])
  }, [modal_toggle]) // Runs whenever modal opens or closes

  useEffect(() => {
    fetchpredefinedwords()
    if (storedgamesAppsData) {
      setInputValue(sessionStorage.getItem("searchedAppMR"))
      setgamesresponse(JSON.parse(storedgamesAppsData))
      setFilteredGames(JSON.parse(storedgamesAppsData))
    }
    // handlePlatformChange(selectedPlatform)
    // console.log(games)
  }, [])

  useEffect(() => {
    const platformFilteredGames =
      selectedPlatform !== "all"
        ? games.filter(game => game.platform === selectedPlatform)
        : games
    setFilteredGames(platformFilteredGames)
  }, [selectedPlatform, games])

  function toggle_modal() {
    setmodal_toggle(!modal_toggle)
    setsecond_modal_toggle(false)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSearch() // Call the function when Enter is pressed
    }
  }

  const fetchpredefinedwords = () => {
    axios
      .get(`${api}/tracker/predefined/keyword`, {})
      .then(res => {
        console.log(res.data.keywords)
        setpredef(res.data.keywords)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
    //https://api.triviumpro.in/predefined/keywords
    // axios.get(`${api}/tracker/predefined/keyword`).then(res => {
    //   console.log(res.data.details)
    //   setpredef(res.data.details)
    // })
  }
  const fetchSearchResult = async value => {
    //storing input value
    sessionStorage.setItem("searchedAppMR", value)
    try {
      // Initial API call to get the action and details
      let res = await axios.get(`${api}/tracker/keyword/${value}`, {})
      setDataAction(res.data.action)

      //toggle_modal()

      // Polling until the action becomes "display"
      while (res.data.action.toLowerCase() !== "display") {
        console.log("Waiting for action to become 'display'...")

        // Wait for 2 seconds before fetching again
        await new Promise(resolve => setTimeout(resolve, 2000))

        // Fetch again to check the action
        res = await axios.get(`${api}/tracker/keyword/${value}`)
        setDataAction(res.data.action)
      }

      // Once action is "display", fetch the games
      console.log("Action is 'display', fetching games...")
      sessionStorage.setItem("games", JSON.stringify(res.data.games))
      // console.log(res.data.games)
      setgamesresponse(res.data.games)
      // setFilteredGames(res.data.games)

      sessionStorage.setItem("filteredGames", JSON.stringify(res.data.games))
      const platformFilteredGames =
        selectedPlatform !== "all"
          ? res.data.games.filter(game => game.platform === selectedPlatform)
          : res.data.games
      setFilteredGames(platformFilteredGames)

      handlePlatformChange(selectedPlatform)
      toggle_modal()
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      console.log(selectedPlatform)
    }
  }
  const handleSearch = async () => {
    toggle_modal()
    await fetchSearchResult(inputValue)
  }

  const predefSearch = async value => {
    setInputValue(value)
    toggle_modal()
    await fetchSearchResult(value)
  }
  // Callback function to handle the platform and select data from the navtabs
  const handlePlatformChange = platform => {
    changePlatform(platform) // Update the platform
    const platformFilteredGames =
      platform !== "all"
        ? games.filter(game => game.platform === platform)
        : games // If "all", return all games
    setFilteredGames(platformFilteredGames)
  }

  const calculatedRevenue = filteredGames => {
    let sum = 0
    filteredGames.map(game => {
      sum = sum + (game?.revenue || 0)
    })
    return `$ ${formatNumber(sum.toFixed(2))}`
  }

  const formatNumber = num => {
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + "M" // Convert to millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + "K" // Convert to thousands
    }
    return num // Return as is if less than 1000
  }

  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb title="Keyword Search" breadcrumbItem="Keyword Inpector" />
        <Row>
          <div>
            <p>See search results from any stores in Realtime</p>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="d-flex flex-wrap gap-3">
                <div className="search-box chat-search-box position-relative col-sm-auto">
                  <Input
                    type="text"
                    className="form-control bg-light border-light rounded"
                    placeholder="Search..."
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)} // Update input value
                    onKeyDown={handleKeyDown}
                  />
                  <i className="uil uil-search search-icon"></i>
                </div>
                <Button
                  color="primary"
                  className=" col-sm-auto waves-effect waves-light"
                  //   onClick={() => {
                  //     toggle_modal()
                  //   }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </div>
              {/* <div className="d-flex flex-wrap gap-3">
                <label className="md-3 col-form-label">Select Platform</label>
                <div className="md-3 col-sm-auto ">
                  <select className="form-control">
                    <option>Select</option>
                    <option>Platform1</option>
                    <option>Platform2</option>
                  </select>
                </div>
              </div> */}
            </div>
          </div>
        </Row>
        <hr className="mt-4"></hr>
        <Row className="mt-4">
          <NavTabs onPlatformChange={handlePlatformChange} />
        </Row>

        <Row className="mt-4">
          <h6>Keyword Intelligence</h6>
          <Col xs={12} className="mt-2">
            <div className="d-flex flex-wrap justify-content-center gap-3">
              <div style={{ width: "32%", minWidth: "150px" }}>
                <ProgressCard heading="Popularity" count="0" />
              </div>
              <div style={{ width: "32%", minWidth: "150px" }}>
                <ProgressCard
                  heading="Total Revenue"
                  count={calculatedRevenue(filteredGames)}
                  // percentage={calculatedRevenue(filteredGames)}
                />
              </div>
              <div style={{ width: "32%", minWidth: "150px" }}>
                <ProgressCard
                  heading="Total Apps"
                  count={filteredGames.length}
                  // percentage={filteredGames.length}
                />
              </div>
            </div>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <p>Words Commonly used</p>
          <div className="d-flex flex-wrap gap-2">
            {predefKeyword.map((value, index) => {
              return (
                <Button
                  key={index}
                  color="dark"
                  className="btnparent btn-rounded"
                  onClick={() => predefSearch(value)}
                >
                  <div className="keywordbtn">{value}</div>
                </Button>
              )
            })}
          </div>
          <div className="mt-4">
            {/* <PerformanceTable data={[1, 2]} /> */}
            <PerformanceDataTable
              inputdata={inputValue}
              gamesData={filteredGames}
            />
          </div>
        </Row>
      </div>

      {/* Loading Modal */}
      <Modal
        isOpen={modal_toggle}
        toggle={() => {
          toggle_modal()
        }}
        centered
        size="lg"
        style={{ maxHeight: "100vh" }}
      >
        <ModalBody
          style={{
            maxHeight: "100vh",
            minHeight: "40vh",
            padding: "20px",
            // overflowY: "auto",
          }}
          className="position-relative"
        >
          <div className="d-flex justify-content-center">
            <Spinner size="" className="mt-4" color="primary" />
          </div>
          <div className="">
            <p className="mt-4 text-center">
              <b>Wait While we are fetching your data</b>
            </p>
            <p className="mt-1 text-center">Fun Fact: {randomFact}</p>
          </div>
          <div className="mt-5 pb-5" style={{ maxWidth: "100%" }}>
            <StepperProgress
              closeModal={() => {
                toggle_modal()
              }}
              isFetched={dataAction}
            />
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
