import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import { FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa"

import { api } from "../../utils/variables"
import classnames from "classnames"
import FeaturesTable from "../../additional_components/marketResearch/appTracking/FeaturesTable"
import axios from "axios"
import { Link } from "react-router-dom"

export default function ComparisonTabs({ appListData }) {
  const [activeTab1, setactiveTab1] = useState("1")
  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [appHistoryData, setAppHistoryData] = useState([])
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setLoader(true)
    fetchAppHistory(appListData)
    // console.log(appHistoryData)
  }, [appListData])
  const fetchAppHistory = async appListData => {
    try {
      // Use Promise.all to fetch data for each app based on item_id
      const appDataPromises = appListData.map(app => {
        const itemId = app.game_details.item_id
        // console.log(itemId)
        // Send item_id as a query parameter with GET request
        return axios.post(
          `${api}/tracker/tracking/app.history`,
          { item_id: itemId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
      })
      const responses = await Promise.all(appDataPromises)
      //console.log("Fetched responses:", responses)

      // Filter and map histories from responses
      const histories = responses
        .filter(response => response && response.data.history)
        .map(response => response.data.history)

      setAppHistoryData(histories)
      console.log(histories)
    } catch (error) {
      console.error("Error fetching app history data:", error)
    } finally {
      setLoader(false)
    }
  }
  const formatDate = dateString => {
    const date = new Date(dateString)

    // Extract individual components
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0") // getMonth() returns 0-based month
    const year = String(date.getFullYear()).slice(-2) // Get last 2 digits of the year
    let hours = date.getHours()
    const minutes = String(date.getMinutes()).padStart(2, "0")

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM"

    // Convert 24-hour format to 12-hour format
    hours = hours % 12
    hours = hours ? String(hours).padStart(2, "0") : "12" // 0 should be displayed as 12

    // Format in dd-mm-yy hh:mm AM/PM
    return `${day}-${month}-${year} ${hours}:${minutes} ${period}`
  }

  return (
    <>
      <Nav pills>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "1",
            })}
            onClick={() => {
              toggle1("1")
            }}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">Features</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab1 === "2",
            })}
            onClick={() => {
              toggle1("2")
              // setLoader(true)
              // fetchAppHistory(appListData)
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Performance</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab1} className="p-3 text-muted">
        <TabPane tabId="1">
          {appListData?.length != 0 ? (
            <FeaturesTable appListData={appListData} />
          ) : (
            ""
          )}
          {/* <FeaturesTable appListData={appListData} /> */}
        </TabPane>
        <TabPane tabId="2">
          <div className="table-responsive">
            <Table className="table table-dark mb-0">
              {loader ? (
                <Row className="mt-4">
                  <div className="d-flex justify-content-center">
                    <Spinner size="lg" color="primary" />
                  </div>
                </Row>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th className="col-md-4" style={{ opacity: "70%" }}>
                        Feature
                      </th>
                      {appListData?.map((app, index) => {
                        return (
                          <th
                            className="text-center"
                            style={{ minWidth: "120px" }}
                          >
                            {app.game_details.name}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Downloads */}
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        Downloads
                      </td>
                      {appHistoryData?.map((app, index) => {
                        const len = app.historic_entries?.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }
                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastDownloads = lastEntry?.d || 0
                        const secondLastDownloads = secondLastEntry?.d || 0

                        return (
                          <>
                            {lastDownloads > secondLastDownloads ? (
                              <td className="text-center">
                                {lastDownloads}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleUp color="green" />
                                  {`${lastDownloads - secondLastDownloads}`}
                                  {")"}
                                </span>
                              </td>
                            ) : lastDownloads < secondLastDownloads ? (
                              <td className="text-center">
                                {lastDownloads}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleDown color="red" />
                                  {`${secondLastDownloads - lastDownloads}`}
                                  {")"}
                                </span>
                              </td>
                            ) : (
                              <td className="text-center">{lastDownloads}</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    {/* Ratings */}
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        Ratings
                      </td>
                      {appHistoryData?.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        // Calculate the overall ratings for the last two elements
                        const getOverallRating = rating => {
                          const isTotalRatingAvailable =
                            rating?.total_rating || false
                          return isTotalRatingAvailable
                            ? (
                                (rating.rating_index["1"] +
                                  rating.rating_index["2"] * 2 +
                                  rating.rating_index["3"] * 3 +
                                  rating.rating_index["4"] * 4 +
                                  rating.rating_index["5"] * 5) /
                                rating.total_rating
                              ).toFixed(1)
                            : 0.0
                        }

                        const lastRating = getOverallRating(
                          app[len - 1]?.rating
                        )
                        const secondLastRating = getOverallRating(
                          app[len - 2]?.rating
                        )

                        return (
                          <>
                            {lastRating > secondLastRating ? (
                              <td className="text-center">
                                {lastRating}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleUp color="green" />
                                  {`${lastRating - secondLastRating}`}
                                  {")"}
                                </span>
                              </td>
                            ) : lastRating < secondLastRating ? (
                              <td className="text-center">
                                {lastRating}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleDown color="red" />
                                  {`${secondLastRating - lastRating}`}
                                  {")"}
                                </span>
                              </td>
                            ) : (
                              <td className="text-center">{lastRating}</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    {/* Keyword Relevance */}
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        Keyword Relevance
                      </td>
                      {appHistoryData?.map((app, index) => {
                        const len = app.historic_entries?.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastKTR = (lastEntry?.ktr || 0).toFixed(2)
                        const secondLastKTR = secondLastEntry?.ktr.toFixed(2)
                        const difference = Math.abs(
                          lastKTR - secondLastKTR
                        ).toFixed(2)
                        return (
                          <>
                            {lastKTR > secondLastKTR ? (
                              <td className="text-center">
                                {lastKTR}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleUp color="green" />
                                  {`${difference}`}
                                  {")"}
                                </span>
                              </td>
                            ) : lastKTR < secondLastKTR ? (
                              <td className="text-center">
                                {lastKTR}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleDown color="red" />
                                  {`${difference}`}
                                  {")"}
                                </span>
                              </td>
                            ) : (
                              <td className="text-center">{lastKTR}</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    {/* Keyword Usage */}
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        Keyword Usage
                      </td>
                      {appHistoryData?.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        // const lastKU = app[len - 1]?.ku

                        // const secondLastKU = app[len - 2]?.ku

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastKU = lastEntry?.ku || 0
                        const secondLastKU = secondLastEntry?.ku || 0

                        const difference = Math.abs(
                          lastKU - secondLastKU
                        ).toFixed(0)
                        return (
                          <>
                            {lastKU > secondLastKU ? (
                              <td className="text-center">
                                {lastKU}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleUp color="green" />
                                  {`${difference}`}
                                  {")"}
                                </span>
                              </td>
                            ) : lastKU < secondLastKU ? (
                              <td className="text-center">
                                {lastKU}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleDown color="red" />
                                  {`${difference} `}
                                  {")"}
                                </span>
                              </td>
                            ) : (
                              <td className="text-center">{lastKU}</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    {/* AUR */}
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        Average User Rating
                      </td>
                      {appHistoryData?.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastAUR = (lastEntry?.aur || 0).toFixed(2)
                        const secondLastAUR = (
                          secondLastEntry?.aur || 0
                        ).toFixed(2)
                        const difference = Math.abs(
                          lastAUR - secondLastAUR
                        ).toFixed(2)
                        return (
                          <>
                            {lastAUR > secondLastAUR ? (
                              <td className="text-center">
                                {lastAUR}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleUp color="green" />
                                  {`${difference}`}
                                  {")"}
                                </span>
                              </td>
                            ) : lastAUR < secondLastAUR ? (
                              <td className="text-center">
                                {lastAUR}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleDown color="red" />
                                  {`${difference} `}
                                  {")"}
                                </span>
                              </td>
                            ) : (
                              <td className="text-center">{lastAUR}</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    {/* URV */}
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        User Reviews Volume
                      </td>
                      {appHistoryData?.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastURV = lastEntry?.urv || 0
                        const secondLastURV = secondLastEntry?.urv || 0
                        const difference = Math.abs(
                          lastURV - secondLastURV
                        ).toFixed(0)
                        return (
                          <>
                            {lastURV > secondLastURV ? (
                              <td className="text-center">
                                {lastURV}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleUp color="green" />
                                  {`${difference}`}
                                  {")"}
                                </span>
                              </td>
                            ) : lastURV < secondLastURV ? (
                              <td className="text-center">
                                {lastURV}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleDown color="red" />
                                  {`${difference} `}
                                  {")"}
                                </span>
                              </td>
                            ) : (
                              <td className="text-center">{lastURV}</td>
                            )}
                          </>
                        )
                      })}
                    </tr>

                    {/* ASR */}
                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}>
                        App Store Score
                      </td>
                      {appHistoryData?.map((app, index) => {
                        const len = app.length
                        if (len < 2) {
                          return <td className="text-center">-</td> // If there are fewer than 2 entries
                        }

                        const historicEntries = app?.historic_entries || []
                        const lastEntry = historicEntries.at(-1) // Last entry
                        const secondLastEntry = historicEntries.at(-2) // Second-last entry

                        // Extract the "d" values
                        const lastASR = (lastEntry?.asr || 0).toFixed(2)
                        const secondLastASR = (
                          secondLastEntry?.asr || 0
                        ).toFixed(2)
                        const difference = Math.abs(
                          lastASR - secondLastASR
                        ).toFixed(2)
                        return (
                          <>
                            {lastASR > secondLastASR ? (
                              <td className="text-center">
                                {lastASR}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleUp color="green" />
                                  {`${difference}`}
                                  {")"}
                                </span>
                              </td>
                            ) : lastASR < secondLastASR ? (
                              <td className="text-center">
                                {lastASR}{" "}
                                <span>
                                  {"("}
                                  <FaAngleDoubleDown color="red" />
                                  {`${difference} `}
                                  {")"}
                                </span>
                              </td>
                            ) : (
                              <td className="text-center">{lastASR}</td>
                            )}
                          </>
                        )
                      })}
                    </tr>
                    {/* Updated */}
                    <tr>
                      <td className="col-md-4" style={{ opacity: "70%" }}>
                        Tracked On
                      </td>
                      {appListData?.map((app, index) => {
                        const updatedAt = formatDate(app?.updated_at)
                        return <td className="text-center">{updatedAt}</td>
                      })}
                    </tr>

                    <tr>
                      <td className="sidecol" style={{ opacity: "70%" }}></td>
                      {appListData?.map((app, index) => {
                        return (
                          <td td className="text-center">
                            <Link
                              to={`/gamedashboard?id=${app.game_details?.item_id}`}
                              className=""
                              state={{
                                app_id: app.game_details?.item_id,
                                data: app.game_details,
                                selected_tab: "track",
                              }}
                            >
                              <Button color="primary">More Details</Button>
                            </Link>
                          </td>
                        )
                      })}
                    </tr>
                  </tbody>
                </>
              )}
            </Table>
          </div>
        </TabPane>
      </TabContent>
    </>
  )
}
