import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { FaCirclePlus } from "react-icons/fa6"
import { Link } from "react-router-dom"
import { api } from "../../utils/variables"
import axios from "axios"
import AppSearchModal from "../../additional_components/common/Modals/AppSearchModal"
import metalogo from "../../../src/assets/images/meta-logo.png"
import sidelogo from "../../../src/assets/images/SideQuest-Mark-White.png"
import Select from "react-select"
import CategoryTrackingDataTable from "../../additional_components/marketResearch/categoryTracking/CategoryDataTable"
import classnames from "classnames"
import GetCSVModal from "../../additional_components/common/Modals/GetCSVModal"
import CollapsibleTrackedWords from "../KeywordTracking/CollapsibleTrackedWords"

export default function CategoryTracking() {
  // const [activeTab1, setactiveTab1] = useState("5")

  const [inputValue, setInputValue] = useState("")
  const [modal_toggle, setmodal_toggle] = useState(false)

  const [trackedCategories, setTrackedCategories] = useState([])

  const [selectedApp, setSelectedApp] = useState(null)
  const [selectedApp1, setSelectedApp1] = useState(null)
  const [selectedApp2, setSelectedApp2] = useState(null)
  const [selectedApp3, setSelectedApp3] = useState(null)

  const [loading, setLoading] = useState(false)
  const [sectionLoading, setSectionLoading] = useState(false)
  const [csvLoader, setCsvLoader] = useState(false)
  const [keywordLoading, setKeywordLoading] = useState(false)
  const [csvModal_toggle, setcsvModal_toggle] = useState(false)
  const [predefKeyword, setpredef] = useState({})
  const [loadedCategory, setLoadedCategory] = useState([])
  const [optionGroup, setOptionGroup] = useState([])

  const [trackedCategoriesData, setTrackedCategoriesData] = useState({})
  const [trackedCategories2, setTrackedCategories2] = useState({})

  const storedgameAppData = sessionStorage.getItem("selectedAppCategory")
  const storedloadedCategory = sessionStorage.getItem("sessionLoadedCategories")
  const selectedCatList = sessionStorage.getItem("selectedCategoryList")

  const [isAddDisabled, setIsAddDisabled] = useState(false)

  const [activeTab, setactiveTab] = useState("1")

  function toggletab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }
  function toggle_modalcsv() {
    setcsvModal_toggle(!csvModal_toggle)
  }

  const customStyles = {
    menu: provided => ({
      ...provided,
      color: "#000", // Darker font color for the menu
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#000", // White for selected, black for others
      backgroundColor: state.isSelected ? "#007bff" : "transparent", // Highlight selected option
      ":hover": {
        backgroundColor: "#50a5f1", // Light gray on hover
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: "#000", // Darker font for the selected value
    }),
  }

  useEffect(() => {
    getTrackedApps()
    // console.log(sessionStorage.getItem("firstAppCategory"))
    setSelectedApp1(JSON.parse(sessionStorage.getItem("firstAppCategory")))
    setSectionLoading(true)
    trackCategory(
      JSON.parse(sessionStorage.getItem("firstAppCategory"))?.item_id
    )

    getAllCategories(
      JSON.parse(sessionStorage.getItem("firstAppCategory"))?.item_id
    )
    setSelectedApp(JSON.parse(sessionStorage.getItem("firstAppCategory")))
    setSelectedApp2(JSON.parse(sessionStorage.getItem("secondAppCategory")))
    setSelectedApp3(JSON.parse(sessionStorage.getItem("thirdAppCategory")))
  }, [])

  useEffect(() => {
    setSectionLoading(true)
    trackCategory(selectedApp1?.item_id)
  }, [selectedApp1])

  function toggle_modal() {
    setmodal_toggle(!modal_toggle)
  }
  const handleselectedapp = async app => {
    // setLoading(true)

    axios.get(`${api}/tracker/app/${app.item_id}`, {}).then(res => {
      setSelectedApp(res.data.details)
      switch (activeTab) {
        case "1":
          setSelectedApp1(res.data.details)
          sessionStorage.setItem(
            "firstAppCategory",
            JSON.stringify(res.data.details)
          )
          break
        case "2":
          setSelectedApp2(res.data.details)
          sessionStorage.setItem(
            "secondAppCategory",
            JSON.stringify(res.data.details)
          )
          break
        case "3":
          setSelectedApp3(res.data.details)
          sessionStorage.setItem(
            "thirdAppCategory",
            JSON.stringify(res.data.details)
          )
          break
      }

      //adding session element
      sessionStorage.setItem(
        "selectedAppCategory",
        JSON.stringify(res.data.details)
      )
      //   console.log(res.data.details)
    })
    getAllCategories(app.item_id)
    trackCategory(app.item_id)
    toggle_modal()
  }

  async function getAllCategories(gameid) {
    // const gameid = { item_id: game }
    // const category = { category: "sandbox" }
    try {
      const res = await axios.get(
        `${api}/tracker/tracking/category?item_id=${gameid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          //data: { item_id: game, category: "sandbox" }, // Adjust this key if the payload requires a different name
        }
      )
      //console.log(res.data.data)
      setLoadedCategory(res.data.data)
      // setpredef(res.data.suggested)
      setpredef(prev => ({
        ...prev,
        [gameid]: res.data.suggested,
      }))
      // console.log(res.data.suggested)
      const apiTags = res.data.data
      const options = apiTags.map(tag => ({
        label: tag,
        value: tag,
      }))
      //adding session element for loaded category
      sessionStorage.setItem(
        "sessionLoadedCategories",
        JSON.stringify(res.data.data)
      )

      // Set the option group
      setOptionGroup([
        {
          options: options,
        },
      ])
    } catch (error) {
      console.error("Error getting data:", error)
    }
  }

  //add keyword by clicking add button
  const handleAddButton1 = async gTab => {
    setIsAddDisabled(true)
    setKeywordLoading(true)
    setLoading(true)
    try {
      if (!trackedCategories.includes(inputValue.value.trim())) {
        //setTrackedKeyword(prevItems => [...prevItems, inputValue.value.trim()]) // Add inputValue to items
      }
      let selectedApp = "G1"
      if (gTab === "G1") {
        selectedApp = selectedApp1 // Use selectedApp1 for gtag === 'g1'
      } else if (gTab === "G2") {
        selectedApp = selectedApp2 // Use selectedApp2 for gtag === 'g2'
      } else if (gTab === "G3") {
        selectedApp = selectedApp3 // Use selectedApp3 for gtag === 'g3'
      }
      console.log(selectedApp)
      if (selectedApp != null) {
        addtoTrackCategory(selectedApp.item_id, inputValue.value)

        // trackCategory(selectedApp.item_id, inputValue.value)
      }
      setInputValue("")
    } catch (error) {
      console.error("Error getting data:", error)
    } finally {
      setIsAddDisabled(false)
    }
  }

  async function trackCategory(game_id) {
    //setLoading(true)
    // console.log(game_id, category)
    let loadingData = true
    while (loadingData) {
      try {
        const res = await axios.get(
          `${api}/tracker/tracking/get_category_tracking?item_id=${game_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
            //   params: {
            //     item_id: game_id,
            //     category: selectedCategory,
            //   },
          }
        )
        const trackedCat = res.data?.data || []

        // setTrackedCategoriesData(trackedCat)

        setTrackedCategoriesData(prev => ({
          ...prev,
          [game_id]: trackedCat,
        }))

        sessionStorage.setItem(
          "selectedCategoryList",
          JSON.stringify(res.data?.data || [])
        )

        //extracting keywords for individual app
        const extractedCategories = Object.values(res.data?.data)
          .filter(item => typeof item === "object" && item.category) // Filter objects with the keyword property
          .map(item => item.category) // Map to keyword values

        setTrackedCategories2(prev => ({
          ...prev,
          [game_id]: extractedCategories,
        }))

        sessionStorage.setItem(
          "addedCategories",
          JSON.stringify(extractedCategories)
        )
        // console.log(extractedCategories)

        setTrackedCategories(extractedCategories)

        const hasEmptyHistoricalData = Object.values(res.data?.data).some(
          item =>
            Array.isArray(item?.matching_historical_data) &&
            item.matching_historical_data.length === 0
        )

        loadingData = hasEmptyHistoricalData
        setIsAddDisabled(false)
        console.log(`is empty: ${loadingData}`)
      } catch (error) {
        console.error("Error getting data:", error)
        break
      } finally {
        setLoading(false)
        setKeywordLoading(false)
        setSectionLoading(false)
      }
    }
  }

  async function addtoTrackCategory(game_id, selectedCategory) {
    const gamedata = { item_id: game_id, category: selectedCategory }
    //const cat = {  }
    // console.log(gamedata)
    try {
      const res = await axios.post(
        `${api}/tracker/tracking/add_category_tracking`,
        gamedata,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          // data: { item_id: gameid, category: selectedCategory }, // Adjust this key if the payload requires a different name
        }
      )
      //console.log(res)

      await trackCategory(game_id)
    } catch (error) {
      console.error("Error getting data:", error)
    } finally {
      setIsAddDisabled(false)
    }
  }

  //removing word from keyword array
  const removeItem = async (gameid, word) => {
    setLoading(true)
    const data = { game_id: gameid, entity_type: "category", entity_id: word }
    try {
      const res = await axios.post(
        `${api}/tracker/tracking/remove_entity`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          // data: { game_id: gameid, entity_type: "keyword", entity_id: word },
        }
      )
      await trackCategory(gameid)
      console.log(res)
    } catch (error) {
      console.error("Error getting data:", error)
    }
  }

  const removeApp = async gameid => {
    console.log(gameid)
    setLoading(true)
    const data = { game_id: gameid, entity_type: "category", entity_id: null }
    try {
      const res = await axios.post(
        `${api}/tracker/tracking/remove_entity`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          // data: { game_id: gameid, entity_type: "keyword", entity_id: word },
        }
      )
      console.log(res)
      await getTrackedApps()
    } catch (error) {
      console.error("Error getting data:", error)
    } finally {
      setLoading(false)
    }
  }
  const getTrackedApps = async () => {
    if (JSON.parse(sessionStorage.getItem("firstAppCategory")) == null)
      setLoading(true)
    try {
      const res = await axios.get(
        `${api}/tracker/tracking/get_user_tracked_category`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      const apps = res.data?.data?.game_data || []
      setSelectedApp1(apps?.[0] || null)
      sessionStorage.setItem(
        "firstAppCategory",
        JSON.stringify(apps?.[0] || null)
      )
      setSelectedApp2(apps?.[1] || null)
      sessionStorage.setItem(
        "secondAppCategory",
        JSON.stringify(apps?.[1] || null)
      )
      setSelectedApp3(apps?.[2] || null)
      sessionStorage.setItem(
        "thirdAppCategory",
        JSON.stringify(apps?.[2] || null)
      )
      // console.log(apps)
    } catch (error) {
      console.error("Error getting data:", error)
    } finally {
      setLoading(false)
    }
  }
  const getAppDetails = (app, tabnum) => {
    axios.get(`${api}/tracker/app/${app?.item_id}`, {}).then(res => {
      switch (tabnum) {
        case "1":
          if (selectedApp1) setSelectedApp1(res.data.details)
          break
        case "2":
          if (selectedApp2) setSelectedApp2(res.data.details)
          break
        case "3":
          if (selectedApp3) setSelectedApp3(res.data.details)
          break
      }
    })
  }

  async function getCsv() {
    const data = { game: selectedApp?.item_id, type: "category" }
    try {
      const res = await axios.post(`${api}/analytics/generate_csv`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      alert(res.data?.message)
    } catch (error) {
      console.log(error)
    } finally {
      setCsvLoader(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content m-3">
        <Breadcrumb
          title="Category Tracking"
          breadcrumbItem="Category Tracking"
        />
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                setSectionLoading(true)
                toggletab("1")
                setSelectedApp(selectedApp1)
                trackCategory(selectedApp1?.item_id)
                //getAppDetails(selectedApp1, "1")
                getAllCategories(selectedApp1?.item_id)
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Game 1</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                setSectionLoading(true)
                toggletab("2")
                setSelectedApp(selectedApp2)
                trackCategory(selectedApp2?.item_id)
                //getAppDetails(selectedApp2, "2")
                getAllCategories(selectedApp2?.item_id)
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-user"></i>
              </span>
              <span className="d-none d-sm-block">Game 2</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                setSectionLoading(true)
                toggletab("3")
                setSelectedApp(selectedApp3)
                trackCategory(selectedApp3?.item_id)
                //getAppDetails(selectedApp3, "3")
                getAllCategories(selectedApp3?.item_id)
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-envelope"></i>
              </span>
              <span className="d-none d-sm-block">Game 3</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row className="m-3">
              <Card className="">
                <CardBody>
                  <Row className="mt-3">
                    <div className="d-flex justify-content-between">
                      {/* <p>Select an App</p> */}
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                      {selectedApp1 == null ? (
                        <div className="col-md-2">
                          <Card
                            className="app-card-container2  border border-1 rounded"
                            onClick={toggle_modal} // Replace with your click handler
                            style={{
                              border: "none",
                              cursor: "pointer", // Makes the card look clickable
                              transition: "background-color 0.3s ease", // Smooth transition for hover effect
                              minHeight: 80,
                            }}
                          >
                            <CardBody className=" mx-auto">
                              <div
                                className="d-flex gap-2 my-auto mb-2"
                                style={{ minHeight: 180 }}
                              >
                                <FaCirclePlus size={20} className="my-auto" />
                                <p className="my-auto">
                                  <b>Select game</b>
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      ) : (
                        <Card className="w-100">
                          <Row className="g-0 align-items-center">
                            <Col md={5}>
                              <CardImg
                                className="img-fluid p-2"
                                src={
                                  selectedApp1?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 200,
                                  maxHeight: 200,
                                  minWidth: 250,
                                  objectfit: "cover",
                                }}
                              />
                            </Col>
                            <Col md={7}>
                              <CardBody>
                                {/* <CardTitle className="display-1">

                          </CardTitle> */}
                                <b className="display-6 text-white">
                                  {selectedApp1?.name || "Loading.."}
                                </b>

                                <CardText>
                                  {selectedApp1?.category || "category"}
                                </CardText>
                                <CardText>
                                  <div className="d-flex justify-content-start gap-4">
                                    <a
                                      href={selectedApp1?.website_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {selectedApp1?.publisher || "publisher"}
                                    </a>
                                    <Link
                                      to={`/publisherapps?item_id=${selectedApp1?.item_id}`}
                                    >
                                      <p>
                                        All games from:{" "}
                                        {selectedApp1?.publisher || "publisher"}
                                      </p>
                                    </Link>
                                  </div>
                                </CardText>

                                <div className="d-flex justify-content-start gap-1">
                                  <a
                                    href={selectedApp1?.platform_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {selectedApp1?.platform == "meta" ? (
                                      <img
                                        src={metalogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 30,
                                          maxHeight: 30,
                                          minWidth: 40,
                                          maxWidth: 40,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={sidelogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 20,
                                          maxHeight: 20,
                                          minWidth: 20,
                                          maxWidth: 20,
                                        }}
                                      />
                                    )}
                                  </a>
                                  <small className="text-muted align-self-center">
                                    Last updated 3 mins ago
                                  </small>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </div>
                  </Row>

                  {selectedApp1 != null ? (
                    <Row style={{ paddingBottom: 20 }}>
                      <div className="d-flex justify-content-center gap-2">
                        {/* <Button
                          className="col-md-3"
                          color={"primary"}
                          onClick={toggle_modal}
                          style={{
                            cursor: "pointer", // Change cursor based on selection
                            // Different color for already selected apps
                          }}
                        >
                          Select Another App
                        </Button> */}
                        <Button
                          className="col-md-3"
                          color="danger"
                          onClick={() => removeApp(selectedApp1?.item_id)}
                        >
                          {" "}
                          Remove app
                        </Button>
                      </div>
                    </Row>
                  ) : (
                    <></>
                  )}
                </CardBody>
              </Card>
            </Row>

            {/* DATA Sections */}
            {selectedApp1 && (
              <>
                <Row>
                  <div className="d-flex justify-content-center">
                    <Button
                      color="primary"
                      onClick={() => {
                        setSectionLoading(true)
                        trackCategory(selectedApp1.item_id)
                        getAllCategories(selectedApp1.item_id)
                      }}
                    >
                      Refresh Data
                    </Button>
                  </div>
                </Row>
                {sectionLoading ? (
                  <>
                    <Row className="mt-4">
                      <div className="d-flex justify-content-center">
                        <Spinner size="lg" color="primary" />
                      </div>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="m-3">
                      <Card>
                        <CardBody>
                          <CardTitle>Category Selection</CardTitle>

                          <div className="d-flex flex-wrap gap-2 mt-3">
                            {predefKeyword[selectedApp1.item_id]?.map(
                              (value, index) => {
                                return (
                                  <Button
                                    key={index}
                                    color="dark"
                                    className="btnparent btn-rounded"
                                    onClick={() => {
                                      setIsAddDisabled(true)
                                      setKeywordLoading(true)
                                      setLoading(true)
                                      //console.log(`selected word:${value}`)

                                      if (
                                        !trackedCategories.includes(
                                          value.trim()
                                        )
                                      ) {
                                        // setTrackedKeyword(prevItems => [
                                        //   ...prevItems,
                                        //   value.trim(),
                                        // ])
                                      }
                                      addtoTrackCategory(
                                        selectedApp1.item_id,
                                        value
                                      )
                                    }}
                                  >
                                    <div className="keywordbtn">{value}</div>
                                  </Button>
                                )
                              }
                            )}
                          </div>
                          <Row className="mb-4 mt-4">
                            <Label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-2 col-form-label"
                            >
                              Enter Category
                            </Label>

                            <Col xl={6}>
                              <div className="mb-3 w-full">
                                <Select
                                  value={inputValue}
                                  onChange={inputValue => {
                                    setInputValue(inputValue)
                                    console.log(inputValue)
                                  }}
                                  options={optionGroup}
                                  styles={customStyles}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col sm={2}>
                              <Button
                                color="primary"
                                disabled={inputValue ? isAddDisabled : "true"}
                                // disabled={isAddDisabled}
                                onClick={() => {
                                  // setIsAddDisabled(true)
                                  // setKeywordLoading(true)
                                  handleAddButton1("G1")
                                }}
                              >
                                {keywordLoading ? (
                                  <Spinner size="sm" color="white" />
                                ) : (
                                  "ADD"
                                )}
                              </Button>
                            </Col>
                          </Row>
                          <CollapsibleTrackedWords
                            trackedKeyword={trackedCategories2}
                            selectedApp={selectedApp1}
                            removeItem={removeItem}
                          ></CollapsibleTrackedWords>
                          {/* <Row>
                            <div className="d-flex flex-wrap justify-content-start align-items-center gap-2">
                              <b className="col-sm-2">Tracked Categories:</b>
                              {trackedCategories2[selectedApp1.item_id]?.map(
                                (value, index) => {
                                  return (
                                    <div id="liveAlertPlaceholder">
                                      <div>
                                        <Alert
                                          color="primary"
                                          isOpen={true}
                                          toggle={() =>
                                            removeItem(
                                              selectedApp1.item_id,
                                              value
                                            )
                                          }
                                        >
                                          {value}
                                        </Alert>
                                      </div>
                                    </div>
                                  )
                                }
                              )}
                            </div>
                          </Row> */}
                        </CardBody>
                      </Card>
                    </Row>

                    {/* Comparison tables and charts */}
                    <Row className="m-3">
                      {trackedCategoriesData[selectedApp1.item_id] && (
                        <CategoryTrackingDataTable
                          // categoryData={trackedCategories}
                          categoryData={
                            trackedCategoriesData[selectedApp1.item_id]
                          }
                        ></CategoryTrackingDataTable>
                      )}
                      <Button
                        className="col-md-1 mt-2"
                        color="success"
                        onClick={() => {
                          setSelectedApp(selectedApp1)
                          toggle_modalcsv()
                          // getCsv()
                        }}
                      >
                        {csvLoader ? (
                          <div className="d-flex justify-content-center">
                            <Spinner size="sm" color="white" />
                          </div>
                        ) : (
                          "Get CSV"
                        )}
                      </Button>
                    </Row>

                    <Row className="m-3 ReportCard">
                      <Card>
                        <CardBody>
                          <CardTitle>Report</CardTitle>
                          <p>This is the report for the data above</p>
                        </CardBody>
                      </Card>
                    </Row>
                  </>
                )}
              </>
            )}
          </TabPane>
          <TabPane tabId="2">
            <Row className="m-3">
              <Card className="">
                <CardBody>
                  <Row className="mt-3">
                    <div className="d-flex justify-content-between">
                      {/* <p>Select an App</p> */}
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                      {selectedApp2 == null ? (
                        <div className="col-md-2">
                          <Card
                            className="app-card-container2  border border-1 rounded"
                            onClick={toggle_modal} // Replace with your click handler
                            style={{
                              border: "none",
                              cursor: "pointer", // Makes the card look clickable
                              transition: "background-color 0.3s ease", // Smooth transition for hover effect
                              minHeight: 80,
                            }}
                          >
                            <CardBody className=" mx-auto">
                              <div
                                className="d-flex gap-2 my-auto mb-2"
                                style={{ minHeight: 180 }}
                              >
                                <FaCirclePlus size={20} className="my-auto" />
                                <p className="my-auto">
                                  <b>Select game</b>
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      ) : (
                        <Card className="w-100">
                          <Row className="g-0 align-items-center">
                            <Col md={5}>
                              <CardImg
                                className="img-fluid p-2"
                                src={
                                  selectedApp2?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 200,
                                  maxHeight: 200,
                                  minWidth: 250,
                                  objectfit: "cover",
                                }}
                              />
                            </Col>
                            <Col md={7}>
                              <CardBody>
                                {/* <CardTitle className="display-1">

                          </CardTitle> */}
                                <b className="display-6 text-white">
                                  {selectedApp2?.name || "Demo1"}
                                </b>

                                <CardText>
                                  {selectedApp2?.category || "category"}
                                </CardText>
                                <CardText>
                                  <div className="d-flex justify-content-start gap-4">
                                    <a
                                      href={selectedApp2?.website_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {selectedApp2?.publisher || "publisher"}
                                    </a>
                                    <Link
                                      to={`/publisherapps?item_id=${selectedApp2?.item_id}`}
                                    >
                                      {" "}
                                      <p>
                                        All games from:{" "}
                                        {selectedApp2?.publisher || "publisher"}
                                      </p>
                                    </Link>
                                  </div>
                                </CardText>

                                <div className="d-flex justify-content-start gap-1">
                                  <a
                                    href={selectedApp2?.platform_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {selectedApp2?.platform == "meta" ? (
                                      <img
                                        src={metalogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 30,
                                          maxHeight: 30,
                                          minWidth: 40,
                                          maxWidth: 40,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={sidelogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 20,
                                          maxHeight: 20,
                                          minWidth: 20,
                                          maxWidth: 20,
                                        }}
                                      />
                                    )}
                                  </a>
                                  <small className="text-muted align-self-center">
                                    Last updated 3 mins ago
                                  </small>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </div>
                  </Row>

                  {selectedApp2 != null ? (
                    <Row style={{ paddingBottom: 20 }}>
                      <div className="d-flex justify-content-center gap-2">
                        {/* <Button
                          className="col-md-3"
                          color={"primary"}
                          onClick={toggle_modal}
                          style={{
                            cursor: "pointer", // Change cursor based on selection
                            // Different color for already selected apps
                          }}
                        >
                          Select Another App
                        </Button> */}
                        <Button
                          className="col-md-3"
                          color="danger"
                          onClick={() => removeApp(selectedApp2?.item_id)}
                        >
                          {" "}
                          Remove app
                        </Button>
                      </div>
                    </Row>
                  ) : (
                    <></>
                  )}
                </CardBody>
              </Card>
            </Row>

            {selectedApp2 && (
              <>
                <Row>
                  <div className="d-flex justify-content-center">
                    <Button
                      color="primary"
                      onClick={() => {
                        //setLoading(true)
                        setSectionLoading(true)
                        trackCategory(selectedApp2.item_id)
                        getAllCategories(selectedApp2.item_id)
                      }}
                    >
                      Refresh Data
                    </Button>
                  </div>
                </Row>
                {/* DATA Section */}
                {sectionLoading ? (
                  <>
                    <Row className="mt-4">
                      <div className="d-flex justify-content-center">
                        <Spinner size="lg" color="primary" />
                      </div>
                    </Row>
                  </>
                ) : (
                  <>
                    {" "}
                    <Row className="m-3">
                      <Card>
                        <CardBody>
                          <CardTitle>Category Selection</CardTitle>

                          <div className="d-flex flex-wrap gap-2 mt-3">
                            {predefKeyword[selectedApp2.item_id]?.map(
                              (value, index) => {
                                return (
                                  <Button
                                    key={index}
                                    color="dark"
                                    className="btnparent btn-rounded"
                                    onClick={() => {
                                      setIsAddDisabled(true)
                                      setKeywordLoading(true)
                                      setLoading(true)
                                      //console.log(`selected word:${value}`)

                                      if (
                                        !trackedCategories.includes(
                                          value.trim()
                                        )
                                      ) {
                                        // setTrackedKeyword(prevItems => [
                                        //   ...prevItems,
                                        //   value.trim(),
                                        // ])
                                      }
                                      addtoTrackCategory(
                                        selectedApp2.item_id,
                                        value
                                      )
                                    }}
                                  >
                                    <div className="keywordbtn">{value}</div>
                                  </Button>
                                )
                              }
                            )}
                          </div>
                          <Row className="mb-4 mt-4">
                            <Label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-2 col-form-label"
                            >
                              Enter Category
                            </Label>

                            <Col xl={6}>
                              <div className="mb-3 w-full">
                                <Select
                                  value={inputValue}
                                  onChange={inputValue => {
                                    setInputValue(inputValue)
                                    console.log(inputValue)
                                  }}
                                  options={optionGroup}
                                  styles={customStyles}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col sm={2}>
                              <Button
                                disabled={inputValue ? isAddDisabled : "true"}
                                color="primary"
                                onClick={() => {
                                  // setIsAddDisabled(true)
                                  // setKeywordLoading(true)
                                  handleAddButton1("G2")
                                }}
                              >
                                {keywordLoading ? (
                                  <Spinner size="sm" color="white" />
                                ) : (
                                  "ADD"
                                )}
                              </Button>
                            </Col>
                          </Row>
                          <CollapsibleTrackedWords
                            trackedKeyword={trackedCategories2}
                            selectedApp={selectedApp2}
                            removeItem={removeItem}
                          ></CollapsibleTrackedWords>
                        </CardBody>
                      </Card>
                    </Row>
                    {/* Comparison tables and charts */}
                    <Row className="m-3">
                      {trackedCategoriesData[selectedApp2.item_id] && (
                        <CategoryTrackingDataTable
                          // categoryData={trackedCategories}
                          categoryData={
                            trackedCategoriesData[selectedApp2.item_id]
                          }
                        ></CategoryTrackingDataTable>
                      )}
                      <Button
                        className="col-md-1 mt-3"
                        color="success"
                        onClick={() => {
                          setSelectedApp(selectedApp2)
                          toggle_modalcsv()
                          // getCsv()
                        }}
                      >
                        {csvLoader ? (
                          <div className="d-flex justify-content-center">
                            <Spinner size="sm" color="white" />
                          </div>
                        ) : (
                          "Get CSV"
                        )}
                      </Button>
                    </Row>
                    <Row className="m-3 ReportCard">
                      <Card>
                        <CardBody>
                          <CardTitle>Report</CardTitle>
                          <p>This is the report for the data above</p>
                        </CardBody>
                      </Card>
                    </Row>
                  </>
                )}
              </>
            )}
          </TabPane>
          <TabPane tabId="3">
            <Row className="m-3">
              <Card className="">
                <CardBody>
                  <Row className="mt-3">
                    <div className="d-flex justify-content-between">
                      {/* <p>Select an App</p> */}
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                      {selectedApp3 == null ? (
                        <div className="col-md-2">
                          <Card
                            className="app-card-container2  border border-1 rounded"
                            onClick={toggle_modal} // Replace with your click handler
                            style={{
                              border: "none",
                              cursor: "pointer", // Makes the card look clickable
                              transition: "background-color 0.3s ease", // Smooth transition for hover effect
                              minHeight: 80,
                            }}
                          >
                            <CardBody className=" mx-auto">
                              <div
                                className="d-flex gap-2 my-auto mb-2"
                                style={{ minHeight: 180 }}
                              >
                                <FaCirclePlus size={20} className="my-auto" />
                                <p className="my-auto">
                                  <b>Select game</b>
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      ) : (
                        <Card className="w-100">
                          <Row className="g-0 align-items-center">
                            <Col md={5}>
                              <CardImg
                                className="img-fluid p-2"
                                src={
                                  selectedApp3?.image_url ||
                                  "https://via.placeholder.com/50"
                                }
                                alt="image cap"
                                style={{
                                  minHeight: 200,
                                  maxHeight: 200,
                                  minWidth: 250,
                                  objectfit: "cover",
                                }}
                              />
                            </Col>
                            <Col md={7}>
                              <CardBody>
                                {/* <CardTitle className="display-1">

                          </CardTitle> */}
                                <b className="display-6 text-white">
                                  {selectedApp3?.name || "Demo1"}
                                </b>

                                <CardText>
                                  {selectedApp3?.category || "category"}
                                </CardText>
                                <CardText>
                                  <div className="d-flex justify-content-start gap-4">
                                    <a
                                      href={selectedApp3?.website_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {selectedApp3?.publisher || "publisher"}
                                    </a>
                                    <Link
                                      to={`/publisherapps?item_id=${selectedApp3?.item_id}`}
                                    >
                                      {" "}
                                      <p>
                                        All games from:{" "}
                                        {selectedApp3?.publisher || "publisher"}
                                      </p>
                                    </Link>
                                  </div>
                                </CardText>

                                <div className="d-flex justify-content-start gap-1">
                                  <a
                                    href={selectedApp3?.platform_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {selectedApp3?.platform == "meta" ? (
                                      <img
                                        src={metalogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 30,
                                          maxHeight: 30,
                                          minWidth: 40,
                                          maxWidth: 40,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={sidelogo}
                                        className="rounded avatar-sm align-self-center"
                                        alt=""
                                        style={{
                                          minHeight: 20,
                                          maxHeight: 20,
                                          minWidth: 20,
                                          maxWidth: 20,
                                        }}
                                      />
                                    )}
                                  </a>
                                  <small className="text-muted align-self-center">
                                    Last updated 3 mins ago
                                  </small>
                                </div>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </div>
                  </Row>

                  {selectedApp3 != null ? (
                    <Row style={{ paddingBottom: 20 }}>
                      <div className="d-flex justify-content-center gap-2">
                        {/* <Button
                          className="col-md-3"
                          color={"primary"}
                          onClick={toggle_modal}
                          style={{
                            cursor: "pointer", // Change cursor based on selection
                            // Different color for already selected apps
                          }}
                        >
                          Select Another App
                        </Button> */}
                        <Button
                          className="col-md-3"
                          color="danger"
                          onClick={() => removeApp(selectedApp3?.item_id)}
                        >
                          {" "}
                          Remove app
                        </Button>
                      </div>
                    </Row>
                  ) : (
                    <></>
                  )}
                </CardBody>
              </Card>
            </Row>

            {/* DATA Section */}
            {selectedApp3 && (
              <>
                <Row>
                  <div className="d-flex justify-content-center">
                    <Button
                      color="primary"
                      onClick={() => {
                        // setLoading(true)
                        setSectionLoading(true)
                        trackCategory(selectedApp3.item_id)
                        getAllCategories(selectedApp3.item_id)
                      }}
                    >
                      Refresh Data
                    </Button>
                  </div>
                </Row>
                {sectionLoading ? (
                  <>
                    <Row className="mt-4">
                      <div className="d-flex justify-content-center">
                        <Spinner size="lg" color="primary" />
                      </div>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="m-3">
                      <Card>
                        <CardBody>
                          <CardTitle>Category Selection</CardTitle>
                          <div className="d-flex flex-wrap gap-2 mt-3">
                            {predefKeyword[selectedApp3.item_id]?.map(
                              (value, index) => {
                                return (
                                  <Button
                                    key={index}
                                    color="dark"
                                    className="btnparent btn-rounded"
                                    onClick={() => {
                                      setIsAddDisabled(true)
                                      setKeywordLoading(true)
                                      setLoading(true)
                                      //console.log(`selected word:${value}`)

                                      if (
                                        !trackedCategories.includes(
                                          value.trim()
                                        )
                                      ) {
                                        // setTrackedKeyword(prevItems => [
                                        //   ...prevItems,
                                        //   value.trim(),
                                        // ])
                                      }
                                      addtoTrackCategory(
                                        selectedApp3.item_id,
                                        value
                                      )
                                    }}
                                  >
                                    <div className="keywordbtn">{value}</div>
                                  </Button>
                                )
                              }
                            )}
                          </div>
                          <Row className="mb-4 mt-4">
                            <Label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-2 col-form-label"
                            >
                              Enter Category
                            </Label>

                            <Col xl={6}>
                              <div className="mb-3 w-full">
                                <Select
                                  value={inputValue}
                                  onChange={inputValue => {
                                    setInputValue(inputValue)
                                    console.log(inputValue)
                                  }}
                                  options={optionGroup}
                                  styles={customStyles}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col sm={2}>
                              <Button
                                color="primary"
                                disabled={inputValue ? isAddDisabled : "true"}
                                onClick={() => {
                                  // setIsAddDisabled(true)
                                  // setKeywordLoading(true)
                                  handleAddButton1("G3")
                                }}
                              >
                                {keywordLoading ? (
                                  <Spinner size="sm" color="white" />
                                ) : (
                                  "ADD"
                                )}
                              </Button>
                            </Col>
                          </Row>

                          <CollapsibleTrackedWords
                            trackedKeyword={trackedCategories2}
                            selectedApp={selectedApp3}
                            removeItem={removeItem}
                          ></CollapsibleTrackedWords>
                        </CardBody>
                      </Card>
                    </Row>

                    {/* Comparison tables and charts */}
                    <Row className="m-3">
                      {trackedCategoriesData[selectedApp3.item_id] && (
                        <CategoryTrackingDataTable
                          // categoryData={trackedCategories}
                          categoryData={
                            trackedCategoriesData[selectedApp3.item_id]
                          }
                        ></CategoryTrackingDataTable>
                      )}
                      <Button
                        className="col-md-1 mt-3"
                        color="success"
                        onClick={() => {
                          setSelectedApp(selectedApp3)
                          toggle_modalcsv()
                          // getCsv()
                        }}
                      >
                        {csvLoader ? (
                          <div className="d-flex justify-content-center">
                            <Spinner size="sm" color="white" />
                          </div>
                        ) : (
                          "Get CSV"
                        )}
                      </Button>
                    </Row>

                    <Row className="m-3 ReportCard">
                      <Card>
                        <CardBody>
                          <CardTitle>Report</CardTitle>
                          <p>This is the report for the data above</p>
                        </CardBody>
                      </Card>
                    </Row>
                  </>
                )}
              </>
            )}
          </TabPane>
        </TabContent>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <Spinner size="lg" color="primary" />
        </div>
      )}
      <AppSearchModal
        modaltoggle={modal_toggle}
        togglemodal={toggle_modal}
        onAppSelect={handleselectedapp}
      ></AppSearchModal>
      <GetCSVModal
        modaltoggle={csvModal_toggle}
        togglemodal={toggle_modalcsv}
        gameId={selectedApp?.item_id}
        dataType="category"
      ></GetCSVModal>
    </React.Fragment>
  )
}
