import axios from "axios"
import { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import { api } from "../../../utils/variables"
import NavTabs from "../../NavTabs"

export default function GetCSVModal({
  modaltoggle,
  togglemodal,
  gameId,
  dataType,
}) {
  const formatDateToYYYYMMDD = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // Add 1 to month (0-indexed) and pad with leading zero
    const day = String(date.getDate()).padStart(2, "0") // Pad day with leading zero if necessary
    return `${year}-${month}-${day}`
  }

  const onemonthbackDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1 - 1).padStart(2, "0") // Add 1 to month (0-indexed) and pad with leading zero
    const day = String(date.getDate()).padStart(2, "0") // Pad day with leading zero if necessary
    // if (month == 0) return `${year}-12-${day}`
    // else return `${year}-${month}-${day}`
    return `${year}-${month}-${day}`
  }

  const [startDate, setStartDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(new Date()))
  const [csvLoader, setCsvLoader] = useState(false)

  async function getCsv() {
    const data = {
      game: gameId,
      type: dataType,
      start_date: startDate,
      end_date: endDate,
    }
    try {
      const res = await axios.post(`${api}/analytics/generate_csv`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      console.log(res)
      //alert(res.data?.message)
      if (res.data?.link) {
        const link = document.createElement("a")
        link.href = res.data.link
        link.setAttribute("download", "") // This ensures it downloads instead of opening
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // Cleanup
      }
    } catch (error) {
      console.log(error)
    } finally {
      setCsvLoader(false)
    }
  }
  return (
    <Modal
      isOpen={modaltoggle}
      toggle={() => {
        togglemodal()
      }}
      centered
      size="md"
      style={{ maxHeight: "100vh" }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Select Date Range
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            togglemodal()
          }}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody
        style={{
          maxHeight: "80vh",
          minHeight: "25vh",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <Row className="mt-4">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="d-flex flex-wrap gap-4">
              <label htmlFor="example-date-input" className="col-form-label">
                From
              </label>
              <div className="">
                <input
                  className="form-control"
                  type="date"
                  defaultValue={startDate}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={e => setStartDate(e.target.value)}
                  id="example-date-input"
                />
              </div>
            </div>
            <div className="d-flex flex-wrap gap-4">
              <label htmlFor="example-date-input" className="col-form-label">
                To
              </label>
              <div className="">
                <input
                  className="form-control"
                  type="date"
                  defaultValue={endDate}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={e => setEndDate(e.target.value)}
                  id="example-date-input"
                />
              </div>
            </div>
          </div>
        </Row>
        <Row className="mt-4 flex-wrap justify-content-center">
          <Button
            className="col-md-6"
            color="success"
            onClick={async () => {
              setCsvLoader(true)
              await getCsv()
              togglemodal()
            }}
          >
            {csvLoader ? (
              <div className="d-flex justify-content-center">
                <Spinner size="sm" color="white" />
              </div>
            ) : (
              "Download CSV"
            )}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  )
}
