import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import NavTabs from "../../additional_components/NavTabs"
import axios from "axios"
import { api } from "../../utils/variables"
import AppCard from "../../additional_components/marketResearch/AppCard"
import TopAppCardM from "./TopAppCardM"
export default function Topapps() {
  const [trendingApps, setTrendingApps] = useState([])
  const [newReleases, setNewReleases] = useState([])
  const [preOrders, setPreOrders] = useState([])
  const [mostPopular, setMostPopular] = useState([])
  const [topSelling, setTopSelling] = useState([])
  const [topEarning, setTopEarning] = useState([])

  const [itemsTopsToShow, setItemsTopsToShow] = useState(6)

  const [loading, setLoading] = useState(false)
  // Initially show 9 items

  const [showTopAll, setShowTopAll] = useState(false)
  const [viewMode, setViewMode] = useState("both") // "selling", "earning", "both"

  const isTopSelling = sessionStorage.getItem("topSellingMeta")
  const isTopEarning = sessionStorage.getItem("topEarningMeta")

  useEffect(() => {
    setLoading(true)
    fetchAll()
  }, [])

  const fetchAll = () => {
    if (isTopSelling || isTopEarning) {
      setLoading(false)
      setTopSelling(JSON.parse(isTopSelling))
      setTopEarning(JSON.parse(isTopEarning))
    }
    try {
      fetchMostPopular()
      fetchNewReleases()
      fetchTrendingApps()
      fetchPreOrder()
      fetchTopSelling()
      fetchTopEarning()
    } finally {
    }
  }

  const fetchTrendingApps = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/trending`)
      setTrendingApps(res.data.games)
      sessionStorage.setItem("trendingMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching in trending:", error)
    } finally {
      setLoading(false)
    }
  }
  const fetchNewReleases = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/releases`)
      setNewReleases(res.data.games)
      sessionStorage.setItem("releaseMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching in releases:", error)
    }
  }

  const fetchPreOrder = async () => {
    try {
      const res = await axios.get(
        `${api}/tracker/stats/meta/coming_soon_pre_orders`
      )
      setPreOrders(res.data.games)
      sessionStorage.setItem("preOrderMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching pre order:", error)
    }
  }
  const fetchMostPopular = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/most_popular`)
      setMostPopular(res.data.games)
      sessionStorage.setItem("popularMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching most popular:", error)
    }
  }
  const fetchTopSelling = async () => {
    try {
      const res = await axios.get(
        `${api}/tracker/stats/meta/top_selling_this_week`
      )
      setTopSelling(res.data.games)
      sessionStorage.setItem("topSellingMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching top selling:", error)
    }
  }
  const fetchTopEarning = async () => {
    try {
      const res = await axios.get(`${api}/tracker/stats/meta/top_earning`)
      setTopEarning(res.data.games)
      sessionStorage.setItem("topEarningMeta", JSON.stringify(res.data.games))
    } catch (error) {
      console.error("Error fetching top earning:", error)
    }
  }

  useEffect(() => {
    if (showTopAll) setItemsTopsToShow(topSelling.length)
    else setItemsTopsToShow(6)
  }, [showTopAll, mostPopular.length]) // Re-run when "showAll" changes or data length changes

  // Group items into slides based on how many we want to show

  const topSellingSlides = []
  for (let i = 0; i < itemsTopsToShow; i += 1) {
    topSellingSlides.push(topSelling[i])
  }

  const topEarningSlides = []
  for (let i = 0; i < itemsTopsToShow; i += 1) {
    topEarningSlides.push(topEarning[i])
  }

  return (
    <React.Fragment>
      <div className="page-content m-3">
        {/* <Breadcrumb title="Top Apps" breadcrumbItem="Top Apps" /> */}

        {/* top earning  */}
        <Row className="mt-4">
          <h4 className="fw-bold mb-3">Top Apps</h4>

          {/* Toggle Buttons */}
          <div className="mb-3 d-flex flex-wrap justify-content-end gap-2">
            {(viewMode === "both" || viewMode === "earning") && (
              <Button
                className="me-2"
                color={viewMode === "selling" ? "primary" : "primary"}
                onClick={() => setViewMode("selling")}
              >
                Show Top Selling
              </Button>
            )}

            {(viewMode === "both" || viewMode === "selling") && (
              <Button
                className="me-2"
                color={viewMode === "earning" ? "primary" : "primary"}
                onClick={() => setViewMode("earning")}
              >
                Show Top Earning
              </Button>
            )}

            {(viewMode === "earning" || viewMode === "selling") && (
              <Button
                color={viewMode === "both" ? "primary" : "primary"}
                onClick={() => setViewMode("both")}
              >
                Show Both
              </Button>
            )}
          </div>

          <Table className="table table-dark mb-0">
            <thead>
              <tr className="text-center">
                <th>Sno.</th>
                {viewMode === "selling" && (
                  <th
                    className={`${
                      viewMode === "selling" || viewMode === "both"
                        ? ""
                        : "text-secondary"
                    }`}
                    colSpan={viewMode === "selling" ? 2 : 1}
                    onClick={() => setViewMode("selling")}
                    style={{ cursor: "pointer" }}
                  >
                    Top Selling
                  </th>
                )}
                {viewMode === "earning" && (
                  <th
                    className={`${
                      viewMode === "earning" || viewMode === "both"
                        ? ""
                        : "text-secondary"
                    }`}
                    colSpan={viewMode === "earning" ? 2 : 1}
                    onClick={() => setViewMode("earning")}
                    style={{ cursor: "pointer" }}
                  >
                    Top Earning
                  </th>
                )}
                {viewMode === "both" && (
                  <>
                    <th
                      className={`${
                        viewMode === "selling" || viewMode === "both"
                          ? ""
                          : "text-secondary"
                      }`}
                      colSpan={viewMode === "selling" ? 2 : 1}
                      onClick={() => setViewMode("selling")}
                      style={{ cursor: "pointer" }}
                    >
                      Top Selling
                    </th>
                    <th
                      className={`${
                        viewMode === "earning" || viewMode === "both"
                          ? ""
                          : "text-secondary"
                      }`}
                      colSpan={viewMode === "earning" ? 2 : 1}
                      onClick={() => setViewMode("earning")}
                      style={{ cursor: "pointer" }}
                    >
                      Top Earning
                    </th>
                  </>
                )}

                {/* <th onClick={() => setViewMode("selling")}>Top Selling</th>
                <th onClick={() => setViewMode("earning")}>Top Earning</th> */}
              </tr>
            </thead>
            <tbody>
              {viewMode === "both"
                ? // ✅ Show normally when both are selected
                  topSellingSlides.map((app, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <TopAppCardM
                          key={topSellingSlides[index]?.item_id}
                          gamedata={topSellingSlides[index]}
                        />
                      </td>
                      <td>
                        <TopAppCardM
                          key={topEarningSlides[index]?.item_id}
                          gamedata={topEarningSlides[index]}
                        />
                      </td>
                    </tr>
                  ))
                : // ✅ Odd-Even split when only one category is selected
                  topSellingSlides
                    .filter((_, index) => index % 2 === 0) // Only take odd indexes
                    .map((app, index) => (
                      <tr key={index}>
                        <th scope="row">{index * 2 + 1}</th>
                        <td>
                          {viewMode === "selling" && (
                            <TopAppCardM
                              key={topSellingSlides[index * 2]?.item_id}
                              gamedata={topSellingSlides[index * 2]}
                            />
                          )}
                          {viewMode === "earning" && (
                            <TopAppCardM
                              key={topEarningSlides[index * 2]?.item_id}
                              gamedata={topEarningSlides[index * 2]}
                            />
                          )}
                        </td>
                        <td>
                          {viewMode === "selling" && (
                            <TopAppCardM
                              key={topSellingSlides[index * 2 + 1]?.item_id}
                              gamedata={topSellingSlides[index * 2 + 1]}
                            />
                          )}
                          {viewMode === "earning" && (
                            <TopAppCardM
                              key={topEarningSlides[index * 2 + 1]?.item_id}
                              gamedata={topEarningSlides[index * 2 + 1]}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
        </Row>

        {!showTopAll && topSelling.length > 9 ? (
          <div className="text-center mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowTopAll(true)}
            >
              See More
            </button>
          </div>
        ) : (
          <div className="text-center mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowTopAll(false)}
            >
              See Less
            </button>
          </div>
        )}

        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
            }}
          >
            <Spinner size="lg" color="primary" />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
