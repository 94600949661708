import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  ACTIVATE_ACCOUNT,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
}

const Login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload || null, loading: false }
      break
    case ACTIVATE_ACCOUNT:
      state = {
        ...state,
        error: null, // Reset error when activating account
      }
      break
    case "LOGIN_ERROR_CLEAR":
      state = { ...state, error: null }
      break
    case "ACTIVATION_SUCCESS":
      state = {
        ...state,
        successMessage: action.payload, // Store success message
        error: null, // Clear errors
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default Login
