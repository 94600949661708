import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Col,
  Row,
  Button,
} from "reactstrap"

export default function TopAppCardM({ key, gamedata }) {
  const formatNumber = num => {
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + " M" // Convert to millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + " K" // Convert to thousands
    }
    const n = Math.round(num)
    return n // Return as is if less than 1000
  }
  return (
    <Link
      to={`/gamedashboard?id=${gamedata?.item_id}`}
      className=""
      state={{ app_id: key, data: gamedata }}
    >
      <Card
        className="app-card-container2 m-2"
        onClick={() => console.log("Card clicked!")} // Replace with your click handler
        style={{
          border: "none",
          cursor: "pointer", // Makes the card look clickable
          transition: "background-color 0.3s ease", // Smooth transition for hover effect
          minWidth: 250,
        }}
      >
        <Row className="g-0 align-items-center">
          <CardBody>
            <div className="d-flex flex-wrap gap-3">
              <CardImg
                className="img-fluid"
                src={gamedata?.image_url || "https://via.placeholder.com/50"}
                alt="image cap"
                style={{
                  minWidth: 80,
                  objectFit: "cover",
                  maxHeight: 150,
                  minHeight: 150,
                }}
              />
              <b className="text-break">{gamedata?.name || "Demo1"}</b>
            </div>
            <div className="mt-2">
              <div className="d-flex flex-wrap justify-content-between mb-0 ">
                <p className="m-0">{gamedata?.category || "category"}</p>
                <div className="d-flex flex-wrap justify-content-start gap-2 align-middle">
                  <p className="m-0">
                    <small className="text-muted">Revenue</small>
                  </p>
                  <p className="m-0">
                    <small className="text-muted">
                      $ {formatNumber(gamedata?.revenue) || "0"}
                    </small>
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between m-0 ">
                <p>
                  <small className="text-muted">
                    ${" "}
                    {gamedata?.price?.amount == 0
                      ? "0"
                      : gamedata?.price?.amount}
                  </small>
                </p>
                <div className="d-flex flex-wrap justify-content-start gap-2">
                  <p>
                    <small className="text-muted">Downloads</small>
                  </p>
                  <p>
                    <small className="text-muted">
                      {formatNumber(gamedata?.downloads) || "0"}
                    </small>
                  </p>
                </div>
              </div>
              {/* <div className="d-flex flex-wrap justify-content-start gap-2">
                <p>
                  <small className="text-muted">Downloads</small>
                </p>
                <p>
                  <small className="text-muted">
                    {formatNumber(gamedata?.downloads) || "0"}
                  </small>
                </p>
              </div> */}
            </div>
          </CardBody>
        </Row>
      </Card>
    </Link>
  )
}
